import { api } from "../apiConfig";
import { decodeServerResponse } from "../utils";
import { arrayDecoder } from "../../types/Decoder";
import { preferenceDecoder } from "../../types/models/Preference";

export async function getPreferenceList() {
  const response = await api.get('/preferences')
  const preferenceListDecoder = arrayDecoder(preferenceDecoder)
  return decodeServerResponse(response).andThen((v) => {
    return preferenceListDecoder.decode(v.data)
  })
}
