import React from 'react'

import HomepageImage4 from '../../assets/images/homepage_04.gif'
import LandingSection from '../LandingSection'

const PolicyMakerSection: React.FC = () => {
  return (
    <LandingSection
      layout="column"
      bottomPadding={false}
      title={
        <h1 style={{ maxWidth: '600px' }}>
          Bringing change makers and policy makers to the same table.
        </h1>
      }
      description={({ className }) => (
        <div className={className} style={{ width: '500px' }}>
          We believe that in order to deal with the 21st century issues we face
          today, we need to bring change makers and policy makers to the same
          table. Join us, and demand a more productive diologue on a local and
          global stage.
        </div>
      )}
      cta={{ to: '/our-mission', label: 'Our mission' }}
    >
      <div style={{ maxWidth: '800px', overflow: 'hidden', marginTop: '45px', marginBottom: '-5px', borderTopRightRadius: "10px", borderTopLeftRadius: "10px", boxShadow: '0px 0px 15px #ededed' }}>
        <img
          src={HomepageImage4}
          style={{ maxWidth: '800px' }}
          alt="Change makers and policy makers."
        />
      </div>
    </LandingSection>
  )
}

export default PolicyMakerSection
