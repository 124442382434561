import React from 'react'
import { Link } from 'react-router-dom'

import PageHeading from '../components/PageHeading'
import SeatsLeft from '../components/SeatsLeft'

import styles from './DonateSuccess.module.scss'

import { joinClasses } from '../utils'

const DonateSuccess: React.FC = () => {
  return (
    <div
      className={styles.donateSuccess}
      data-layout="column center align-center"
    >
      <PageHeading
        prepend="Donate"
        title="Thank you for supporting HumanAct"
        description="Thank you for your donation! Sign up to become one of our pioneer users and be the first one to get early access to HumanAct platform."
      />
      <Link to="/sign-up" className={joinClasses('button', styles.button)}>
        Become a pioneer
      </Link>
      <SeatsLeft />
    </div>
  )
}

export default DonateSuccess
