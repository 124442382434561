import React, { useState } from 'react'
import styles from './AccordionItem.module.scss'
import { joinClasses } from '../utils'

export type Props = {
  title: string
}

const AccordionItem: React.FC<Props> = ({ title, children }) => {
  const [show, setShow] = useState(false)

  return (
    <div className={styles.accordionItem}>
      <div onClick={() => setShow(!show)} className={styles.title}>
        {title}
      </div>
      <div
        className={joinClasses(styles.content, show && styles.contentActive)}
      >
        {Array.isArray(children) ? (
          children.map((child, i) => <p key={i}>{child}</p>)
        ) : (
          <p>{children}</p>
        )}
      </div>
    </div>
  )
}

export default AccordionItem
