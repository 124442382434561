import React, { ReactNode } from 'react'

import styles from './FormGroup.module.scss'

type Props = {
  label?: string
  column?: boolean
  padding?: string
  children: ReactNode
}

const FormGroup: React.FC<Props> = ({ label, children, column, padding }) => {
  return (
    <div className={styles.formGroup} data-layout-md="row" data-layout="column">
      {label && <div className={styles.label}>{label}</div>}
      <div
        className={styles.content}
        data-layout-md={`${column ? 'column' : 'row'} ${padding}`}
        data-layout="column u2"
      >
        {children}
      </div>
    </div>
  )
}

FormGroup.defaultProps = {
  padding: 'u1'
}

export default FormGroup
