import { objectDecoder, Decoder, numberDecoder } from '../Decoder'

export interface SeatsLeftModel {
  seatsLeft: number
  preLaunchUsers: number
  actionsTakenToSupport: number
}

export const seatsLeftDecoder: Decoder<unknown, SeatsLeftModel> = objectDecoder(
  {
    seatsLeft: numberDecoder,
    preLaunchUsers: numberDecoder,
    actionsTakenToSupport: numberDecoder
  }
)
