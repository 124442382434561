import React from 'react'
import styles from './Milestone.module.scss'
import { TickIcon } from '../../../assets/icons'
import { joinClasses } from '../../../utils'

type Props = {
  name: string
  description: string
  date: string
  done: boolean
  endCap?: boolean
  isNextDone?: boolean
}

const Milestone: React.FC<Props> = ({
  name,
  description,
  date,
  done,
  endCap = false,
  isNextDone = false
}) => {
  return (
    <li
      className={joinClasses(styles.milestone, isNextDone && styles.nextDone)}
      data-layout="row u5"
    >
      <div
        className={joinClasses(styles.checkIcon, done && styles.done)}
        data-layout="row middle align-center"
      >
        {done && <TickIcon />}
      </div>
      <aside>
        <h3>{name}</h3>
        <div className={styles.description}>{description}</div>
        <div className={styles.date}>{date}</div>
      </aside>
      {endCap && <div className={styles.endCap}></div>}
    </li>
  )
}

export default Milestone
