import React from 'react'
import { Link } from 'react-router-dom'

import styles from './Mission.module.scss'
import { CONTENT } from './Mission.content'
import LeadershipProfile from '../components/LeadershipProfile'
import TagLine from '../components/shared/TagLine'
import PageHeading from '../components/PageHeading'
import { joinClasses } from '../utils'

const Mission: React.FC = () => {
  return (
    <div className={styles.mission}>
      <div className={styles.header} data-layout="column align-center">
        <PageHeading
          className={styles.pageHeading}
          title="We believe everyone should be able to participate in building a sustainable future for all."
          prepend="Our mission"
        />
        <Link
          to="/get-involved"
          className={joinClasses('button', styles.button)}
        >
          Get involved
        </Link>
        <TagLine className={styles.tagLine} />
      </div>
      <div
        className={styles.missionSection}
        data-layout="column u3 middle align-center"
      >
        <div className={styles.wrapper}>
          <h1>{CONTENT.title}</h1>
          {CONTENT.mission.map((paragraph, idx) => (
            <p key={idx}>{paragraph}</p>
          ))}
        </div>
      </div>
      <div
        className={styles.leadershipSection}
        data-layout="column u0 align-center middle"
        data-layout-md="column u4 align-center middle"
      >
        <h1>Leadership</h1>
        <div
          className={styles.leadershipProfiles}
          data-layout="row align-center middle"
        >
          {CONTENT.leadership.map(profile => (
            <LeadershipProfile {...profile} key={profile.name} />
          ))}
        </div>
      </div>
      <div
        className={styles.supportSection}
        data-layout="column u4 middle align-center"
      >
        <h1>Support Our Mission</h1>
        <p>
          Be a part of the HumanAct movement and help us reach our goals by
          making a small donation.
        </p>
        <Link to="/donate" className="button">
          Donate
        </Link>
      </div>
    </div>
  )
}

export default Mission
