import AvatarHendrik from '../assets/images/Avatar_Hendrik.jpg'
import AvatarMika from '../assets/images/Avatar_Mika.jpg'
import AvatarPaulina from '../assets/images/Avatar_Paulina.jpg'
import AvatarSara from '../assets/images/Avatar_Sara.jpg'
import AvatarSonali from '../assets/images/Avatar_Sonali.jpg'

export const CONTENT = {
  title: 'Our mission is to open a dialog between leaders of the world and the people.',
  
  mission: [
    'We believe that in the 21st Century, we need new tools to open the lines of communication between citizens of the world and leaders of the world. Now more than ever before in human history, we need to establish a new social contract that includes everyone. Whichever race, color, gender, religion, party, or nation you represent, we all must move the world forward into a more sustainable future.',
    'So we’re building easy tools to empower and support people. Tools that allow anyone to influence the world by sharing an idea and creating a movement or impactful business on a global scale that affects millions of people globally.',
    'We are bringing activists, creators, rebels, philanthropists, investors, policymakers, journalists, and YOU together to create a future that will inspire for generations to come.',
    'Get a seat at the table and join the HumanAct movement.'
  ],
  leadership: [
    {
      name: 'Mika Kozma',
      avatar: AvatarMika,
      position: 'Founder, CEO',
      additionalInfo: [
        'Co-Founder of Roadster',
        'Wikia / Fandom',
        'Cisco',
        'Flip Video'
      ]
    },
    {
      name: 'Sara Wood',
      avatar: AvatarSara,
      position: 'Advisor',
      additionalInfo: [
        'FarFetch, EVP of Product',
        'Gap, VP of Product',
        'Layer, CPO',
        'Wikia / Fandom, SVP of Product',
        'United Nations'
      ]
    },
    {
      name: 'Hendrik Bartel',
      avatar: AvatarHendrik,
      position: 'Advisor',
      additionalInfo: [
        'TrueValue labs, Co-Founder, CEO',
        'Amazon',
        'Autodesk',
        'Cisco'
      ]
    },
    {
      name: 'Sonali Arurkar',
      avatar: AvatarSonali,
      position: 'Advisor',
      additionalInfo: [
        'Catchafire, VP of Partnerships',
        'Change.org, VP of Global Business Development',
        'UniversalGiving, VP'
      ]
    },
    {
      name: 'Paulina Boveignton-Fauran',
      avatar: AvatarPaulina,
      position: 'Advisor',
      additionalInfo: [
        'Head of Finance Unit (ALDE Group) in the European Parliament',
        'Specialist on Anti-Money Laundering Kredyt Bank SA',
        'Trainee at World Bank, Warsaw'
      ]
    }
  ]
}
