import { api } from '../apiConfig'
import { newsletterResponseDecoder } from '../../types/models/Newsletter'
import { objectDecoder, stringDecoder, optional } from '../../types/Decoder'

export async function signUpForNewsletter(email: string) {
  const response = await api.post('/newsletter', { email })
  const responseDecoder = objectDecoder({
    message: stringDecoder,
    data: optional(newsletterResponseDecoder),
    error: optional(objectDecoder({ message: stringDecoder }))
  })
  return responseDecoder.decode(response.data)
}
