import React, { useState, useRef } from 'react'
import styles from './ProfileDropdown.module.scss'
import ProfileAvatar from './ProfileAvatar'
import { User, UserType } from '../types/models/User'
import DropdownMenu from './DropdownMenu'
import DropdownButton from './DropdownButton'
import { useClickOutside } from '../utils'

type Props = {
  user: User
  handleProfileClick: () => void
  handleSignOut: () => void
}

const ProfileDropdown: React.FC<Props> = ({
  user,
  handleProfileClick,
  handleSignOut
}) => {
  const [open, setOpen] = useState(false)

  const dropdownRef = useRef<HTMLDivElement>(null)
  useClickOutside(dropdownRef, () => {
    if (open) {
      setOpen(false)
    }
  })

  const ProfileInfo = (
    <div
      className={styles.profileInfo}
      data-layout="row u2 align-center"
      onClick={() => {
        handleProfileClick()
        setOpen(false)
      }}
    >
      <ProfileAvatar user={user} size="medium" />
      <div data-layout="column u1" data-flex="1">
        <h3>{user.fullName}</h3>
        <div className={styles.type}>
          {user.type === UserType.pioneer ? 'Pioneer member' : 'Member'}
        </div>
      </div>
    </div>
  )

  return (
    <div className={styles.profileDropdown} ref={dropdownRef}>
      <ProfileAvatar user={user} size="small" onClick={() => setOpen(!open)} />
      <DropdownMenu
        className={styles.dropdown}
        open={open}
        header={ProfileInfo}
        buttons={[
          // <DropdownButton key="password">Change Password</DropdownButton>, TODO: uncomment when changing password is implemented
          <DropdownButton
            key="signOut"
            onClick={() => {
              handleSignOut()
              setOpen(false)
            }}
          >
            Sign out
          </DropdownButton>
        ]}
      />
    </div>
  )
}

export default ProfileDropdown
