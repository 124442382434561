import { combineReducers, Reducer } from 'redux'
import { connectRouter, RouterState } from 'connected-react-router'
import { History } from 'history'

import UIReducer, { UiState } from './UI/reducer'
import UserReducer, { UserState } from './User/reducer'

export interface ApplicationState {
  router: RouterState
  UI: UiState
  user: UserState
}

export default (history: History) =>
  combineReducers({
    router: connectRouter(history) as Reducer,
    UI: UIReducer as Reducer,
    user: UserReducer as Reducer
  })
