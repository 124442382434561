export const StripeStyle = {
  base: {
    fontSize: '13px',
    color: '#424242',
    fontFamily: 'Open Sans',
    letterSpacing: '0.65px',
    lineHeight: '18px',
    fontWeight: 300,
    '::placeholder': {
      color: '#a3a3a3'
    }
  },
  invalid: {
    color: '#424242'
  }
}
