import { UpdateSigninModalVisibiltiy, SetMenuOpen } from './actionTypes'

export const toggleSignInModal: () => UpdateSigninModalVisibiltiy = () => ({
  type: 'UPDATE_SIGNIN_MODAL_VISIBILITY'
})

export const setMenuOpen: (value: boolean) => SetMenuOpen = (
  value: boolean
) => ({
  type: 'SET_MENU_OPEN',
  payload: {
    value
  }
})
