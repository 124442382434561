import { createSelector } from 'reselect'
import { ApplicationState } from '../reducers'
import { User } from '../../types/models/User'

const getErrorSelector = (state: ApplicationState): any | undefined =>
  state.user.error

export const getError = createSelector(
  getErrorSelector,
  error => error
)

const getUserSelector = (state: ApplicationState): User | undefined =>
  state.user.data.user

export const getUser = createSelector(
  getUserSelector,
  user => user
)
