import React from 'react'
import styles from './ForgotPassword.module.scss'
import PageHeading from '../components/PageHeading'
import { FormikProps, withFormik, Field } from 'formik'
import * as Yup from 'yup'
import { useDispatch } from 'react-redux'
import { toggleSignInModal } from '../store/UI/actions'
import { forgotPassword } from '../api/public/Auth'
import history from '../services/History'

export type FormProps = {
  email: string
}

const ForgotPassword: React.FC<FormikProps<FormProps>> = ({
  handleSubmit,
  isSubmitting,
  isValid,
  touched,
  errors,
  status
}) => {
  const dispatch = useDispatch()

  const showSignInModal = () => {
    dispatch(toggleSignInModal())
  }

  return (
    <div className={styles.forgotPassword} data-layout="column align-center">
      <PageHeading
        title="Recover account"
        description="Enter the email address used to create your account."
      />
      <form
        onSubmit={handleSubmit}
        data-layout="column"
        className={styles.form}
      >
        <Field
          className={errors.email && touched.email && 'invalid'}
          name="email"
          type="email"
          placeholder="Email"
        />
        <button
          type="submit"
          disabled={isSubmitting || !isValid}
          className={styles.submit}
        >
          Send email
        </button>
        {status.error && <p className={styles.errorMessage}>{status.error}</p>}
        <p className={styles.signIn}>
          Already have an account?{' '}
          <span onClick={showSignInModal}>Sign in</span>
        </p>
      </form>
    </div>
  )
}

export default withFormik<{}, FormProps>({
  mapPropsToValues: () => ({ email: '' }),
  validateOnChange: false,
  validateOnBlur: false,
  mapPropsToStatus: () => ({}),
  validationSchema: Yup.object().shape({
    email: Yup.string()
      .email()
      .required()
  }),
  handleSubmit: (values, props) => {
    forgotPassword(values.email)
      .then(() => {
        history.push('/forgot-password/email')
      })
      .catch(err => {
        props.setStatus({ error: err.response.data.message })
        props.setSubmitting(false)
      })
  }
})(ForgotPassword)
