import React, { useState, useEffect } from 'react'
import { useTransition, animated } from 'react-spring'

import styles from './HeroImage.module.scss'

import { HERO_SLIDER_IMAGES } from '../constants/heroSlider'

const useImageSlider = (duration: number) => {
  const [currentImage, setImage] = useState(HERO_SLIDER_IMAGES[0])
  useEffect(() => {
    const handler = setInterval(() => {
      const currentIndex = HERO_SLIDER_IMAGES.findIndex(
        img => img === currentImage
      )
      const nextIndex = (currentIndex + 1) % HERO_SLIDER_IMAGES.length
      setImage(HERO_SLIDER_IMAGES[nextIndex])
    }, duration)
    return function cleanup() {
      clearInterval(handler)
    }
  })
  return currentImage
}

const HeroImage: React.FC = () => {
  const currentImage = useImageSlider(5000)

  const transitions = useTransition(currentImage, item => item.src, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: {
      tension: 70
    }
  })

  return (
    <div className={styles.heroImage} data-flex="1">
      {transitions.map(({ item, key, props }) => {
        const imageStyles = {
          ...props,
          backgroundImage: `url(${item.src})`
        }
        return (
          <animated.div
            key={key}
            style={imageStyles}
            data-layout="column end"
            className={styles.imageContent}
          >
            <div className={styles.description} data-layout="column u05">
              <strong>{item.place}</strong>
              <div>{item.date}</div>
              <div>{item.credit}</div>
            </div>
          </animated.div>
        )
      })}
    </div>
  )
}

export default HeroImage
