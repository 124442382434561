import React from 'react'

import styles from './Issue.module.scss'

import { getIconFromNameKey } from '../../../assets/icons/issues'
import { joinClasses } from '../../../utils'

type Props = {
  id?: number
  name: string
  iconKey?: string
  handleClick?: (id: number) => void
  active?: (id: number) => boolean
}

const Issue: React.FC<Props> = ({ id, name, iconKey, active, handleClick }) => {
  const Icon = getIconFromNameKey(iconKey || '')

  const onClick = () => {
    handleClick && id && handleClick(id)
  }

  const isActive = () => {
    if (!id || !active) return ''

    return active(id) && styles.active
  }

  return (
    <div
      className={joinClasses(
        styles.issue,
        isActive(),
        handleClick && styles.clickable
      )}
      onClick={onClick}
    >
      {iconKey && <Icon className={styles.issueImage} />}
      <span className={styles.issueTitle}>{name}</span>
    </div>
  )
}

export default Issue
