import React from 'react'
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'
import { StripeProvider } from 'react-stripe-elements'
import { Route } from 'react-router'

import Header from './Header'
import SideMenu from './SideMenu'
import SignInModal from './SignInModal'
import Footer from './Footer'
import UserProvider from './UserProvider'

import { routes } from '../constants/routes'

import store from '../store'

import history from '../services/History'
import { ScrollToTop } from '../utils'

import styles from './App.module.scss'
import AuthRoute from './shared/AuthRoute'

const App: React.FC = () => {
  const API_KEY: string = process.env.REACT_APP_STRIPE_KEY || ''

  return (
    <div className={styles.app}>
      <StripeProvider apiKey={API_KEY}>
        <Provider store={store}>
          <ConnectedRouter history={history}>
            <UserProvider>
              <Header />
              <SideMenu />
              <main className={styles.main}>
                {routes.map(({ path, exact, component, authRequired }) =>
                  authRequired ? (
                    <AuthRoute
                      key={path}
                      path={path}
                      exact={exact}
                      component={component}
                    />
                  ) : (
                    <Route
                      key={path}
                      path={path}
                      exact={exact}
                      component={component}
                    />
                  )
                )}
              </main>
              <Footer />
              <SignInModal></SignInModal>
              <ScrollToTop />
            </UserProvider>
          </ConnectedRouter>
        </Provider>
      </StripeProvider>
    </div>
  )
}

export default App
