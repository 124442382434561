import React, { useEffect, Fragment } from 'react'
import { RouteComponentProps } from 'react-router'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'

import styles from './SignUpActivated.module.scss'

import AccountActivatedImage from '../../assets/images/account-activated.png'

import PageHeading from '../../components/PageHeading'
import Spinner from '../../components/shared/Spinner'

import { requestUserActivate } from '../../store/User/actions'
import { getUser } from '../../store/User/selectors'
import { ApplicationState } from '../../store/reducers'

import { User, UserType } from '../../types/models/User'

import { joinClasses } from '../../utils'

export type Props = {
  activateUser?: typeof requestUserActivate
  user?: User
}

const SignUpActivated: React.FC<Props & RouteComponentProps<{}, {}, {}>> = ({
  location,
  activateUser,
  user
}) => {
  useEffect(() => {
    if (location.search && activateUser) {
      const [_, token] = location.search.match(/token=(.*)&?/) || [null, null]

      if (token) {
        activateUser(token)
      }
    }
  }, [location.search, activateUser])

  return (
    <div className={styles.signUpCountries} data-layout="column align-center">
      {user ? (
        <Fragment>
          <PageHeading
            title={`Thank you, ${user.fullName.split(' ')[0]}`}
            description="Your email has been verified."
          />
          <img
            src={AccountActivatedImage}
            alt="Your email has been verified."
            className={styles.icon}
          />
          <Link
            className={joinClasses('button', styles.continue)}
            to={
              user.type === UserType.pioneer ? '/dashboard' : '/sign-up/donate'
            }
          >
            Continue
          </Link>
        </Fragment>
      ) : (
        <Spinner className={styles.spinner} />
      )}
    </div>
  )
}

const mapStateToProps = (state: ApplicationState) => ({
  user: getUser(state)
})

const mapDispatchToProps = {
  activateUser: requestUserActivate
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SignUpActivated)
