type FAQ = {
  title: string
  content: string | string[]
}

export const DONATE_FAQ: FAQ[] = [
  {
    title: 'Is my contribution tax deductible?',
    content:
      'Contributions or gifts to HumanAct are not deductible as charitable contributions for Federal income tax purposes.'
  },
  {
    title: 'What are the contribution rules?',
    content: [
      'I am a lawful citizen of my country of residence.',
      'This contribution is made from my own funds, and funds are not being provided to me by another person or entity for the purpose of making this contribution.',
      'I am making this contribution with my own personal credit card and not with a corporate or business credit card or a card issued to another person.',
      'I am at least eighteen years old.'
    ]
  },
  {
    title: 'Is my contribution secure?',
    content:
      'Yes. We use Stripe as our third party payments platform to make sure the transaction is secure.'
  },
  {
    title: 'What are the benefits of becoming a Pioneer Member of HumanAct?',
    content:
      'As a Pioneer Member of HumanAct you are the first to take action on the platform and receive special status as an early adopter and supporter of our mission. You will get early access to features we will be releasing and be able to give feedback that will shape the final product. Pioneer Members are also guaranteed a FREE Premium Account once released.'
  }
]

export const LANDING_FAQ: FAQ[] = [
  {
    title: 'Why should I support HumanAct?',
    content:[
      'Big ideas need big support. We are just getting started and need funds in order to build full version of the product. There are 3 main reasons to join our movement:',
      '- You want to see a drastic change in how people communicate with leaders.',
      '- You want to actively participate in building a more sustainable future.',
      '- You are a leader and want to be one of the first 250K Pioneer Members of HumanAct.',
    ]
  },
  {
    title: 'What are the benefits of becoming a Pioneer Member of HumanAct?',
    content:
      'As a Pioneer Member of HumanAct you are the first to take action on the platform and receive special status as an early adopter and supporter of our mission. You will get early access to features we will be releasing and be able to give feedback that will shape the final product. Pioneer Members are also guaranteed a FREE Premium Account once released.'
  },
  {
    title: 'What is the minimum donation amount required to become a Pioneer Member of HumanAct?',
    content:
      '$5 is the minimum donation amount in order to receive the Pioneer Member status on your account.'
  },
  {
    title: 'Is the HumanAct platform already live?',
    content:
      'No, we are in the process of building the full functioning platform, and we need YOUR help to fund it. We decided to do a Pre-Release of HumanAct and try to raise money just as you would be able to raise money for your movement on the full version of the platform. We see HumanAct as a movement itself, and encourage you to join us. '
  },
  {
    title: 'When will I be able to start a movement on HumanAct?',
    content:
      'You will be able to start a movement on the HumanAct platform in Q1 2020. Prior to January 2020, inquiries about Movements can be sent to move@humanact.com.'
  },
  {
    title: 'What kind of movement can I start on HumanAct?',
    content:
      'HumanAct uses the United Nations’ Sustainable Development Goals as a guideline for the types of movements we will facilitate. In order to create a profile for your movement on the HumanAct platform your mission needs to be aligned with one or more of the following categories: Climate Change, Clean and Affordable Energy, Gender Equality, Peace, Justice, Education, Wealth and Well-Being, Inequalities, Poverty, Clean Water and Sanitation, Technology and Innovation, Sustainable Cities and Communities, Consumption and Production, Ecology, Economy, Corruption, Disaster Relief, Immigration, Infrastructure, or Agriculture. As the platform grows, we will revisit and expand this list as needed.'
  },
  {
    title: 'What defines a movement on the HumanAct platform?',
    content:
      'We promote 3 kinds of movements: A Campaign for Social Good, Non-Profit Organization, or Socially Impactful For-Profit Business.'
  },
  {
    title: 'Can For-Profit business also become a movement?',
    content:
      'Yes, as long your mission and business aligns with SDGs (Sustainable Development Goals), and has a positive social impact.'
  }
]
