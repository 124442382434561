import React from 'react'
import styles from './LandingSection.module.scss'
import { Link } from 'react-router-dom'
import { joinClasses } from '../utils'

type CtaProps = {
  label: string
  to: string
}

type LandingSectionProps = {
  layout: 'row' | 'row-reverse' | 'column'
  title?: JSX.Element
  description?: (args: { className: string }) => JSX.Element
  cta?: CtaProps
  bottomPadding?: boolean
  className?: string
}

const LandingSection: React.FC<LandingSectionProps> = ({
  layout,
  title,
  description,
  cta,
  children,
  bottomPadding = true,
  className
}) => {
  const CtaButton = ({ label, to }: CtaProps) => (
    <Link to={to}>
      <button className="wide">{label}</button>
    </Link>
  )

  const rootClasses = joinClasses(
    styles.landingSection,
    styles[`layout_${layout}`],
    bottomPadding && styles.bottomPadding,
    className
  )

  return (
    <div
      className={rootClasses}
      data-layout="column around align-center u4"
      data-layout-md={`${layout} around ${
        layout === 'column' ? 'align-center' : 'align-start'
      }`}
    >
      {(title || description || cta) && (
        <aside
          data-layout="column u4 align-center"
          data-layout-md={`column u5 ${
            layout === 'column' ? 'align-center' : ''
          }`}
          style={{ textAlign: layout === 'column' ? 'center' : 'left' }}
        >
          {title}
          {description && description({ className: styles.description })}
          {cta && <CtaButton {...cta} />}
        </aside>
      )}
      {children}
    </div>
  )
}

export default LandingSection
