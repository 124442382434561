import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { withFormik, FormikProps } from 'formik'
import * as Yup from 'yup'

import styles from './SignInModal.module.scss'
import Modal from './Modal'

import { toggleSignInModal } from '../store/UI/actions'
import { requestUserLogin, resetUserErrorState } from '../store/User/actions'
import { ApplicationState } from '../store/reducers'
import { getError } from '../store/User/selectors'
import { StatusType } from '../constants/statusTypes'

export type Props = {
  formStatus?: StatusType
  isVisible: boolean
  loginError: string
  requestUserLogin: typeof requestUserLogin
  resetUserErrorState: typeof resetUserErrorState
  toggleSignInModal: typeof toggleSignInModal
}

export type FormProps = {
  email: string
  password: string
}

const SignInModal: React.FC<Props & FormikProps<FormProps>> = ({
  errors,
  formStatus,
  handleBlur,
  handleChange,
  handleSubmit,
  isSubmitting,
  isValid,
  isVisible,
  loginError,
  resetForm,
  resetUserErrorState,
  toggleSignInModal,
  touched,
  values
}) => {
  const resetFormState = () => {
    if (
      formStatus === StatusType.FAILED ||
      formStatus === StatusType.SUCCEEDED
    ) {
      resetForm({ email: values.email, password: '' })
    }
  }

  const resetErrors = () => {
    resetUserErrorState()

    return () => {
      resetUserErrorState()
    }
  }

  useEffect(resetFormState, [formStatus])
  useEffect(resetErrors, [isVisible])

  return (
    <React.Fragment>
      {isVisible && (
        <Modal handleClose={toggleSignInModal}>
          <form onSubmit={handleSubmit}>
            <h1 className={styles.heading}>Welcome back</h1>
            <div className={styles.tagLine}>
              Sign in to your existing account to continue.
            </div>
            <div style={{ width: '270px' }} data-layout="column u1">
              <input
                onChange={handleChange}
                onBlur={handleBlur}
                name="email"
                type="email"
                className={`noBorder ${errors.email &&
                  touched.email &&
                  'invalid'}`}
                placeholder="Email"
                value={values.email}
              />
              <input
                onChange={handleChange}
                onBlur={handleBlur}
                name="password"
                type="password"
                className={`noBorder ${errors.password &&
                  touched.password &&
                  'invalid'}`}
                placeholder="Password"
                value={values.password}
              />
            </div>
            <div style={{ width: '270px' }} data-layout="column u2">
              <button
                type="submit"
                disabled={isSubmitting || !isValid}
                className={styles.continue}
              >
                Continue
              </button>
              {loginError && (
                <p className={styles.errorMessage}>{loginError}</p>
              )}
              <p className={styles.termsLine}>
                By signing in you agree to our <br />
                <Link to="/terms-of-service" onClick={toggleSignInModal}>
                  Terms of Service &amp; Privacy Policy
                </Link>
              </p>
              <p className={styles.registerLine}>
                Don't have an account yet?
                <Link to="/sign-up" onClick={toggleSignInModal}>
                  Sign up
                </Link>
              </p>
              <Link
                to="/forgot-password"
                onClick={toggleSignInModal}
                className={styles.forgotPassword}
              >
                Forgot password?
              </Link>
            </div>
          </form>
        </Modal>
      )}
    </React.Fragment>
  )
}

const FormikSignInModal = withFormik<Props, FormProps>({
  mapPropsToValues: () => ({
    email: '',
    password: ''
  }),
  validateOnBlur: false,
  validateOnChange: false,
  validationSchema: Yup.object().shape({
    email: Yup.string()
      .email()
      .required(),
    password: Yup.string()
      .min(3)
      .required()
  }),
  handleSubmit(values, { props: { requestUserLogin } }) {
    requestUserLogin(values.email, values.password)
  }
})(SignInModal)

const mapDispatchToProps = {
  toggleSignInModal,
  requestUserLogin,
  resetUserErrorState
}

const mapStateToProps = (state: ApplicationState) => ({
  isVisible: state.UI.data.signInModalVisible,
  loginError: getError(state),
  formStatus: state.user.status.name
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FormikSignInModal)
