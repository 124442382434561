import React, { ReactNode } from 'react'
import styles from './Accordion.module.scss'
import { joinClasses } from '../utils'

type Props = {
  className?: string
  children: ReactNode
}

const Accordion: React.FC<Props> = ({ children, className }) => {
  return (
    <div
      className={joinClasses(styles.accordion, className)}
      data-layout="column"
    >
      {children}
    </div>
  )
}

export default Accordion
