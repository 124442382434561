import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { ApplicationState } from './store/reducers'

/**
 * Sets return value when windows scroll reaches set breakpoint value.
 */
export const useScrollState = ({ breakpoint }: { breakpoint: number }) => {
  const [scrolled, setScrolled] = useState(false)

  useEffect(() => {
    function handleScrollChange() {
      setScrolled(window.scrollY > breakpoint)
    }

    handleScrollChange() // initial scroll
    window.addEventListener('scroll', handleScrollChange)

    return function cleanup() {
      window.removeEventListener('scroll', handleScrollChange)
    }
  })
  return scrolled
}

export const joinClasses = (...classList: (string | boolean | undefined)[]) => {
  return classList.filter(Boolean).join(' ')
}

/**
 * Detects mouse clicks outside provided element.
 * @param ref Ref to provided element
 * @param callback Callback when click event is detected
 */
export const useClickOutside = (
  ref: React.RefObject<Element>,
  callback: (ev: MouseEvent) => void
) => {
  useEffect(() => {
    const handleGlobalClick = (ev: MouseEvent) => {
      if (ref.current && ev.target) {
        const node = ref.current
        const inside = node.contains(ev.target as Element)
        if (!inside) {
          callback(ev)
        }
      }
    }

    window.addEventListener('click', handleGlobalClick)

    return function cleanup() {
      window.removeEventListener('click', handleGlobalClick)
    }
  })
}

export const roundNumber = (number: number) => {
  return number > 1000 ? `${Math.floor(number / 1000)}K` : number
}

export const selectRandomFromList = <A extends any>(list: A[]): A => {
  const randomIdx = Math.floor(Math.random() * list.length)
  return list[randomIdx]
}

/**
 * Scrolls window to top after every navigation
 */
export const ScrollToTop: React.FC = ({ children }) => {
  const pathname = useSelector<ApplicationState, string>(
    ({ router }) => router.location.pathname
  )
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return <> {children} </>
}

/**
 * Checks if an object is empty
 */
export function isEmpty(obj: any): obj is {} {
  return Object.keys(obj).length === 0
}
