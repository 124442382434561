import {
  numberDecoder,
  stringDecoder,
  optional,
  objectDecoder,
  Decoder
} from '../Decoder'

export interface User {
  uuid: string
  role: string
  id: number
  fullName: string
  email: string
  password: string
  type: UserType
  updatedAt: Date
  createdAt: Date
  locale?: string
  gender?: string
  salutation?: string
  about?: string
  image?: string
  verifiedAt?: Date
  lastLoginAt?: Date
  lastLoginIp?: string
  deletedAt?: Date
}

export enum UserType {
  pioneer = 'pioneer',
  normal = 'normal',
  referral = 'referral',
  other = 'other',
  premium = 'premium'
}

export const userDecoder: Decoder<unknown, User> = objectDecoder({
  uuid: stringDecoder,
  role: stringDecoder,
  id: numberDecoder,
  fullName: stringDecoder,
  email: stringDecoder,
  password: stringDecoder,
  type: stringDecoder,
  updatedAt: stringDecoder,
  createdAt: stringDecoder,
  locale: optional(stringDecoder),
  gender: optional(stringDecoder),
  salutation: optional(stringDecoder),
  about: optional(stringDecoder),
  image: optional(stringDecoder),
  verifiedAt: optional(stringDecoder),
  lastLoginAt: optional(stringDecoder),
  lastLoginIp: optional(stringDecoder),
  deletedAt: optional(stringDecoder)
})
