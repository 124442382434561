import { takeLatest, all, put, call, select } from '@redux-saga/core/effects'

import {
  UserLoginStarted,
  UserLoginSucceeded,
  UserLoginFailed,
  UserLoginRequested,
  UserRegisterRequested,
  UserRegisterStarted,
  UserRegisterSucceeded,
  UserRegisterFailed,
  UserFetchSucceeded,
  UserFetchFailed,
  UserFetchStarted,
  UserActivateRequested,
  UserActivateStarted,
  UserActivateSucceeded,
  UserActivateFailed,
  UserFetchRequested,
  UserLogoutStarted,
  UserLogoutSucceeded,
  UserLogoutFailed
} from './actionTypes'

import { login, register, activateAccount } from '../../api/public/Auth'
import { fetchUser, logout } from '../../api/user/Profile'

import { Result } from '../../types/Result'
import { AuthLogin, AuthRegister } from '../../types/models/Auth'
import { DecodeError } from '../../types/Decoder'
import { User, UserType } from '../../types/models/User'
import { TOKEN_KEY, SKIP_DONATE_KEY } from '../../constants/localStorage'

import { UpdateSigninModalVisibiltiy } from '../UI/actionTypes'
import { push } from 'connected-react-router'
import { ApplicationState } from '../reducers'

function* userFetch() {
  try {
    yield put<UserFetchStarted>({ type: 'USER_FETCH_STARTED' })

    const response: Result<User, DecodeError<unknown>> = yield fetchUser()

    const [data, error] = response.flatMatch()

    if (data) {
      yield put<UserFetchSucceeded>({
        type: 'USER_FETCH_SUCCEEDED',
        payload: { user: data }
      })
    } else if (error) {
      yield put<UserFetchFailed>({
        type: 'USER_FETCH_FAILED',
        payload: { error: error.msg }
      })
    }
  } catch (error) {
    if (error.response.data.message === 'Auth token invalid or missing') {
      yield put<UserFetchFailed>({
        type: 'USER_FETCH_FAILED',
        payload: { error: '' }
      })

      localStorage.removeItem(TOKEN_KEY)
    }
  }
}

function* userLogout() {
  try {
    yield put<UserLogoutStarted>({ type: 'USER_LOGOUT_STARTED' })

    yield call(logout)

    localStorage.removeItem(TOKEN_KEY)

    yield put<UserLogoutSucceeded>({
      type: 'USER_LOGOUT_SUCCEEDED'
    })
  } catch (error) {
    yield put<UserLogoutFailed>({
      type: 'USER_LOGOUT_FAILED',
      payload: { error: '' }
    })
  }
}

function* userLogin(action: UserLoginRequested) {
  try {
    yield put<UserLoginStarted>({ type: 'USER_LOGIN_STARTED' })

    const { email, password } = action.payload

    const response: Result<AuthLogin, DecodeError<unknown>> = yield login(
      email,
      password
    )

    const [data, error] = response.flatMatch()

    if (data) {
      localStorage.setItem(TOKEN_KEY, data.token)

      yield put<UserLoginSucceeded>({
        type: 'USER_LOGIN_SUCCEEDED',
        payload: { token: data.token }
      })

      yield put<UserFetchRequested>({
        type: 'USER_FETCH_REQUESTED'
      })

      yield put<UpdateSigninModalVisibiltiy>({
        type: 'UPDATE_SIGNIN_MODAL_VISIBILITY'
      })

      const state: ApplicationState = yield select()
      const user: User = state.user.data

      if (user.type === UserType.pioneer) {
        yield put(push('/dashboard'))
      } else {
        const skipDonate = localStorage.getItem(SKIP_DONATE_KEY)
        yield put(push(skipDonate ? '/dashboard' : '/sign-up/donate'))
      }
    } else if (error) {
      yield put<UserLoginFailed>({
        type: 'USER_LOGIN_FAILED',
        payload: { error: error.msg }
      })
    }
  } catch (error) {
    yield put<UserLoginFailed>({
      type: 'USER_LOGIN_FAILED',
      payload: { error: error.response.data.message }
    })
  }
}

function* userRegister(action: UserRegisterRequested) {
  try {
    yield put<UserRegisterStarted>({ type: 'USER_REGISTER_STARTED' })

    const { email, password, fullName } = action.payload

    const response: Result<AuthRegister, DecodeError<unknown>> = yield register(
      email,
      password,
      fullName
    )

    const [data, error] = response.flatMatch()

    if (data) {
      localStorage.setItem(TOKEN_KEY, data.token)

      yield put<UserRegisterSucceeded>({
        type: 'USER_REGISTER_SUCCEEDED',
        payload: { user: data.user, token: data.token }
      })

      yield put(push('/sign-up/preferences'))
    } else if (error) {
      yield put<UserRegisterFailed>({
        type: 'USER_REGISTER_FAILED',
        payload: { error: error.msg }
      })
    }
  } catch (error) {
    yield put<UserRegisterFailed>({
      type: 'USER_REGISTER_FAILED',
      payload: { error: error.response.data.message }
    })
  }
}

function* userActivate(action: UserActivateRequested) {
  try {
    yield put<UserActivateStarted>({ type: 'USER_ACTIVATE_STARTED' })

    const { token } = action.payload

    yield activateAccount(token)

    yield put<UserActivateSucceeded>({
      type: 'USER_ACTIVATE_SUCCEEDED'
    })

    if (localStorage.getItem(TOKEN_KEY)) {
      yield put<UserFetchRequested>({
        type: 'USER_FETCH_REQUESTED'
      })
    } else {
      yield put<UpdateSigninModalVisibiltiy>({
        type: 'UPDATE_SIGNIN_MODAL_VISIBILITY'
      })

      yield put(push('/'))
    }
  } catch (error) {
    yield put<UserActivateFailed>({
      type: 'USER_ACTIVATE_FAILED',
      payload: { error: error.response.data.message }
    })

    yield put<UpdateSigninModalVisibiltiy>({
      type: 'UPDATE_SIGNIN_MODAL_VISIBILITY'
    })

    yield put(push('/'))
  }
}

export default function* userSaga() {
  yield all([
    takeLatest('USER_LOGIN_REQUESTED', userLogin),
    takeLatest('USER_REGISTER_REQUESTED', userRegister),
    takeLatest('USER_FETCH_REQUESTED', userFetch),
    takeLatest('USER_ACTIVATE_REQUESTED', userActivate),
    takeLatest('USER_LOGOUT_REQUESTED', userLogout)
  ])
}
