import axios from 'axios'

const apiBaseUrl = process.env.REACT_APP_API || 'http://localhost:5000'

export const api = axios.create({
  baseURL: `${apiBaseUrl}/api`
})

export const userApi = axios.create({
  baseURL: `${apiBaseUrl}/api`
})

userApi.interceptors.request.use(
  config => {
    const token = localStorage.getItem('token')

    config.headers.Authorization = `Bearer ${token}`

    return config
  },
  error => Promise.reject(error)
)
