import React, { useState, useEffect } from 'react'
import Autosuggest, { SuggestionsFetchRequestedParams } from 'react-autosuggest'
import AutosuggestHighlightMatch from 'autosuggest-highlight/match'
import AutosuggestHighlightParse from 'autosuggest-highlight/parse'

import styles from './CountrySelect.module.scss'

import { joinClasses } from '../../utils'

import { CloseCircleIcon } from '../../assets/icons'

import { Country } from '../../types/models/Country'

type Props = {
  alwaysShowRemove?: boolean
  countries: Country[]
  displayNationality?: boolean
  dropdownTitle?: string
  handleChange?: (country: Country | undefined) => void
  handleClear?: () => void
  placeholder?: string
}

const CountrySelect: React.FC<Props> = ({
  alwaysShowRemove,
  countries,
  displayNationality,
  dropdownTitle = 'Select country',
  handleChange,
  handleClear,
  placeholder
}) => {
  const [value, setValue] = useState('')
  const [country, setCountry] = useState<Country>()
  const [currentCountries, setCountries] = useState(countries)

  const getSuggestions = (params: SuggestionsFetchRequestedParams) => {
    setCountries(
      countries
        .filter(({ name, nationality }) =>
          (displayNationality ? nationality : name)
            .toLowerCase()
            .includes(params.value.toLowerCase())
        )
        .slice(0, 5)
    )
  }

  const onSuggsetionsClear = () => {
    return []
  }

  const getSuggestionValue = (country: any) => {
    setCountry(country)
    return displayNationality ? country.nationality : country.name
  }

  const renderSuggestion = (country: any, { query }: { query: string }) => {
    const field = displayNationality ? country.nationality : country.name
    const matches = AutosuggestHighlightMatch(field, query)
    const parts = AutosuggestHighlightParse(field, matches)

    return (
      <div data-layout="row align-center">
        <img src={country.flag} alt={field} />
        <div>
          {parts.map((part, index) => {
            const className = part.highlight ? styles.highlight : undefined

            return className ? (
              <span className={className} key={index}>
                {part.text}
              </span>
            ) : (
              part.text
            )
          })}
        </div>
      </div>
    )
  }

  useEffect(() => {
    if (country && !displayNationality && country.name !== value)
      setCountry(undefined)

    if (country && displayNationality && country.nationality !== value)
      setCountry(undefined)
  }, [country, value, displayNationality])

  useEffect(() => {
    if (handleChange) handleChange(country)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [country])

  const onClearClick = () => {
    setValue('')
    handleClear && handleClear()
  }

  return (
    <div className={styles.countrySelect}>
      {country && (
        <img src={country.flag} className={styles.flag} alt={country.name} />
      )}
      {(country || value || alwaysShowRemove) && (
        <CloseCircleIcon onClick={onClearClick} className={styles.close} />
      )}
      <Autosuggest
        theme={styles}
        inputProps={{
          value,
          placeholder,
          className: joinClasses(
            styles.input,
            country && styles.countrySelected
          ),
          onChange: (_, b) => {
            setValue(b.newValue)
          }
        }}
        getSuggestionValue={getSuggestionValue}
        onSuggestionsFetchRequested={getSuggestions}
        onSuggestionsClearRequested={onSuggsetionsClear}
        renderSuggestion={renderSuggestion}
        renderSectionTitle={section => <div>{section.title}</div>}
        getSectionSuggestions={section => section.countries}
        suggestions={[{ title: dropdownTitle, countries: currentCountries }]}
        multiSection
      />
    </div>
  )
}

export default CountrySelect
