import React from 'react'

import styles from './DonationAmountChooser.module.scss'
import { joinClasses } from '../../utils'

type Props = {
  name: string
  amount?: number
  small?: boolean
  alwaysShowCustom?: boolean
  handleChange: (name: string, value: number) => any
}

const DonationAmountChooser: React.FC<Props> = ({
  amount,
  name,
  small,
  handleChange,
  alwaysShowCustom
}) => {
  const values = [5, 25, 50, 75, 100]
  const customValue = !values.includes(amount || 0)

  return (
    <div>
      <div data-layout="row wrap between" data-layout-md="row between center">
        {values.map((value, i) => (
          <div
            className={joinClasses(
              styles.option,
              value === amount && styles.optionActive,
              small && styles.optionSmall
            )}
            key={i}
            onClick={() => handleChange(name, value)}
          >
            ${value}
          </div>
        ))}
        <div
          className={joinClasses(
            styles.option,
            customValue && styles.optionActive,
            small && styles.optionSmall
          )}
          onClick={() => handleChange(name, 15)}
        >
          Other
        </div>
      </div>
      {(alwaysShowCustom || customValue) && (
        <div
          className={styles.customValue}
          data-layout="column u1"
          data-layout-md="row align-center u0"
        >
          <span>Your donation</span>
          <div data-layout="row">
            <div className={styles.currency}>USD</div>
            <input
              className={styles.customValueInput}
              placeholder="Custom amount"
              value={amount}
              onChange={e => handleChange(name, Number(e.target.value))}
            />
          </div>
          {!small && <small>One-time payment</small>}
        </div>
      )}
    </div>
  )
}

DonationAmountChooser.defaultProps = {
  amount: 25
}

export default DonationAmountChooser
