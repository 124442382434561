import { api } from '../apiConfig'
import { decodeServerResponse } from '../utils'
import { donateDecoder } from '../../types/models/Donator'

export async function donate(data: {
  firstName: string
  lastName: string
  email: string
  comment: string
  amount: number
  'g-recaptcha-response': string
}) {
  const response = await api.post('/donators', data)
  return decodeServerResponse(response).andThen(v => {
    return donateDecoder.decode(v.data)
  })
}
