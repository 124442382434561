import {
  UserLoginRequested,
  UserRegisterRequested,
  UserActivateRequested,
  UserErrorReset,
  UserLogoutRequested
} from './actionTypes'

export const requestUserLogin: (
  email: string,
  password: string
) => UserLoginRequested = (email: string, password: string) => ({
  payload: {
    email,
    password
  },
  type: 'USER_LOGIN_REQUESTED'
})

export const requestUserLogout: () => UserLogoutRequested = () => ({
  type: 'USER_LOGOUT_REQUESTED'
})

export const requestUserRegister: (
  email: string,
  password: string,
  fullName: string
) => UserRegisterRequested = (
  email: string,
  password: string,
  fullName: string
) => ({
  payload: {
    email,
    password,
    fullName
  },
  type: 'USER_REGISTER_REQUESTED'
})

export const requestUserFetch = () => ({
  type: 'USER_FETCH_REQUESTED'
})

export const requestUserActivate: (token: string) => UserActivateRequested = (
  token: string
) => ({
  payload: {
    token
  },
  type: 'USER_ACTIVATE_REQUESTED'
})

export const resetUserErrorState: () => UserErrorReset = () => ({
  type: 'USER_ERROR_RESET'
})
