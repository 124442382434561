import React from 'react'
import { useSpring, useTransition, animated } from 'react-spring'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import { setMenuOpen } from '../store/UI/actions'
import { ApplicationState } from '../store/reducers'
import { Logo } from '../assets'
import { CloseIcon } from '../assets/icons'
import CelebrationTag from './CelebrationTag'

import styles from './SideMenu.module.scss'
import SeatsLeft from './SeatsLeft'

type Props = {
  menuOpen: boolean
  closeMenu: () => void
}

const slideStyles = {
  open: {
    transform: 'translateX(0%)',
    boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.25)'
  },
  closed: {
    transform: 'translateX(-100%)',
    boxShadow: '0px 0px 0px 0px rgba(0, 0, 0, 0.25)'
  }
}

const SideMenu: React.FC<Props> = ({ menuOpen, closeMenu }) => {
  const slide = useSpring({
    ...slideStyles[menuOpen ? 'open' : 'closed'],
    config: { tension: 380, friction: 50 }
  })

  const fadeTransitions = useTransition(menuOpen, null, {
    from: { opacity: 0 },
    enter: { opacity: 0.4 },
    leave: { opacity: 0 }
  })

  return (
    <>
      <animated.div
        className={styles.sideMenu}
        style={slide}
        data-layout="column u4"
      >
        <Link to="/" onClick={closeMenu}>
          <Logo className={styles.logo} />
        </Link>
        <nav data-layout="column u5">
          <ul className={styles.big} data-layout="column u1">
            <li>
              <Link to="/our-mission" onClick={closeMenu}>
                Mission
              </Link>
            </li>
            <li>
              <Link to="/get-involved" data-layout="row between" onClick={closeMenu}>
                Get Involved
                <SeatsLeft showCount={false} text=""  inline className={styles.seatsLeft} />
              </Link>
            </li>
            <li>
              <Link to="/donate" onClick={closeMenu}>
                Donate
              </Link>
            </li>
          </ul>
          <ul data-layout="column u1">
            <li>
              <Link to="/contact-us" onClick={closeMenu}>
                Contact Us
              </Link>
            </li>
            <li>
              <Link to="/cookie-policy" onClick={closeMenu}>
                Cookie Policy
              </Link>
            </li>
            <li>
              <Link to="/terms-of-service" onClick={closeMenu}>
                Terms &amp; Privacy Policy
              </Link>
            </li>
          </ul>
        </nav>
        <div className={styles.message}>
          <p>
            Our team is building a platform for global societal transformation.
            Get involved early to become one of our{' '}
            <Link to="/sign-up">pioneer memebers</Link>.
          </p>
          <p>
            Cheers, <strong>HumanAct Team</strong>
          </p>
        </div>
        <div data-flex="1" data-layout="column middle">
          {menuOpen && <CelebrationTag duration={1500} />}
        </div>
        <div className={styles.close} onClick={closeMenu}>
          <CloseIcon />
        </div>
      </animated.div>
      {fadeTransitions.map(
        ({ item, key, props }) =>
          item && (
            <animated.div
              className={styles.backdrop}
              key={key}
              style={props}
              onClick={closeMenu}
            ></animated.div>
          )
      )}
    </>
  )
}

const mapDispatchToProps = {
  closeMenu: () => setMenuOpen(false)
}

const mapStateToProps = (state: ApplicationState) => ({
  menuOpen: state.UI.data.menuOpen
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SideMenu)
