import React from 'react'
import styles from './FaqSection.module.scss'
import LandingSection from '../LandingSection'
import Accordion from '../Accordion'
import AccordionItem from '../AccordionItem'
import { LANDING_FAQ } from '../../constants/faq'

const FaqSection: React.FC = () => {
  return (
    <LandingSection layout="column" title={<h1>Frequently asked questions</h1>}>
      <div className={styles.faq}>
        <Accordion>
          {LANDING_FAQ.map((data, index) => (
            <AccordionItem title={data.title} key={index}>
              {data.content}
            </AccordionItem>
          ))}
        </Accordion>
      </div>
    </LandingSection>
  )
}

export default FaqSection
