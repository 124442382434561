import React, { useState, useEffect } from 'react'

import styles from './Issues.module.scss'

import Issue from './Issue'

import { getTopicList } from '../../../api/public/Topic'

import { Topic } from '../../../types/models/Topic'

type Props = {
  handleClick?: (id: number) => void
  active?: (id: number) => boolean
}

const Issues: React.FC<Props> = ({ handleClick, active }) => {
  const [issues, setIssues] = useState<Topic[]>([])

  useEffect(() => {
    getTopicList().then(issues => {
      issues.match({
        ok(issuess) {
          setIssues(issuess)
        },
        error(e) {}
      })
    })
  }, [])

  return (
    <div className={styles.issues}>
      {issues.map(({ id, name, nameTranslationKey }) => (
        <Issue
          key={id}
          id={id}
          name={name}
          iconKey={nameTranslationKey}
          handleClick={handleClick}
          active={active}
        />
      ))}
    </div>
  )
}

export default Issues
