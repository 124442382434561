import React from 'react'
import styles from './ForgotPasswordEmail.module.scss'
import PageHeading from '../components/PageHeading'
import EmailSentImage from '../assets/images/email-sent.png'

const ForgotPasswordEmail: React.FC = () => {
  return (
    <div className={styles.signUpCountries} data-layout="column align-center">
      <PageHeading
        title="Check your email"
        description="You’re almost there. Check your email and click the pasword reset link."
      />
      <img src={EmailSentImage} alt="Email sent" className={styles.icon} />
    </div>
  )
}

export default ForgotPasswordEmail
