import React from 'react'

import styles from './Stats.module.scss'

import { roundNumber } from '../../utils'

type Props = {
  stats: { count: number; description: string }[]
}

const Stats: React.FC<Props> = ({ stats }) => {
  return (
    <div
      className={styles.stats}
      data-layout-md={`row u0`}
      data-layout={`column align-center u5`}
    >
      {stats.map(({ count, description }, index) => (
        <div key={index} className={styles.item} data-layout="column">
          <span>{roundNumber(count)}</span>
          <p>{description}</p>
        </div>
      ))}
    </div>
  )
}

export default Stats
