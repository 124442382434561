/* import imageMlk from '../assets/images/hero_mlk.jpg'
import imageWomen from '../assets/images/hero_women.jpg' */
import imageClimateChange from '../assets/images/hero_climatechange.jpg'
import imageGreta from '../assets/images/hero_greta.jpg'
import imageGlacier from '../assets/images/hero_glacier.jpg'
import imageHongKong from '../assets/images/hero_hongkong.jpg'
import imageJacindaArden from '../assets/images/hero_jacindaarden.jpg'
/* import imagePussyhat from '../assets/images/hero_pussyhat.jpg'
import imageMandela from '../assets/images/hero_mandela.jpg' */

export const HERO_SLIDER_IMAGES = [
  /*
  {
    src: imageMlk,
    place: 'New York Speech',
    date: 'Sep 10, 1963',
    credit: 'Martin Luther King'
  },
  {
    src: imageWomen,
    place: 'Washington, DC',
    date: 'Jan 21, 1969',
    credit: 'Nixon’s Inauguration Protest'
  }, */
  {
    src: imageGreta,
    place: 'Sweedish Parliament',
    date: 'Aug 31, 2018',
    credit: 'Greta Thunberg School Strike'
  },
  {
    src: imageClimateChange,
    place: 'Berlin, Germany',
    date: 'Jan 25, 2019',
    credit: 'Fridays For Future'
  },
  {
    src: imageGlacier,
    place: 'Prince William Sound, AL',
    date: 'Feb 12, 2018',
    credit: 'Melting Iceburg'
  },
  {
    src: imageHongKong,
    place: 'Hong Kong',
    date: 'Sep 06, 2019',
    credit: 'photo: Joseph Chan'
  },  
  {
    src: imageJacindaArden,
    place: 'Phillipstown, New Zealend',
    date: 'Mar 16, 2019',
    credit: 'Prime Minister Jacinda Arden'
  },  
  /*{
    src: imageMandela,
    place: 'Soweto Speech',
    date: 'Sep 20, 1990',
    credit: 'Nelson Mandela'
  }, 
  {
    src: imagePussyhat,
    place: 'Washington, DC',
    date: 'Jan 21, 2017',
    credit: 'Women’s March'
  } */
]
