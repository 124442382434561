import React, { Suspense } from 'react'
import ReactSVG from 'react-svg'

/**
 * Import `svg` file and wrap it in `React.lazy` loaded `ReactSVG` component.
 *
 * Used to inline svg files (such as icons).
 * @param path Path to svg relative to `/src/assets`
 */
export function importSvg(path: string) {
  const reactSvgWrapper = (src: string) => ({
    className,
    onClick
  }: {
    className?: string
    onClick?: () => void
  }) => <ReactSVG src={src} className={className} onClick={onClick} />

  // dynamically import svg from path and wrap in ReactSVG component
  const importedPath = import('/' + path) as Promise<{ default: string }>
  const SvgElement = importedPath.then(imported => ({
    default: reactSvgWrapper(imported.default)
  }))

  // use React.lazy to lazy evaluate import promise, and provide fallback
  const LazySvg = React.lazy(() => SvgElement)
  const WithFallback = ({
    className,
    onClick
  }: {
    className?: string
    onClick?: () => void
  }) => (
    <Suspense fallback={<span></span>}>
      <LazySvg
        className={[className, 'svg-wrapper'].filter(Boolean).join(' ')}
        onClick={onClick}
      />
    </Suspense>
  )

  return WithFallback
}
