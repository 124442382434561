import {
  numberDecoder,
  stringDecoder,
  optional,
  objectDecoder,
  Decoder
} from '../Decoder'

export interface NewsletterResponse {
  id: number
  email: string
  updatedAt: string
  createdAt: string
  deletedAt?: string
}

export const newsletterResponseDecoder: Decoder<
  unknown,
  NewsletterResponse
> = objectDecoder({
  id: numberDecoder,
  email: stringDecoder,
  updatedAt: stringDecoder,
  createdAt: stringDecoder,
  deletedAt: optional(stringDecoder)
})
