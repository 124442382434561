import React, { useState, Fragment } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router'

import styles from './Dashboard.module.scss'

import { ScheduleSection } from '../components/landingSections'
import ProfileAvatar from '../components/ProfileAvatar'

import { getUser } from '../store/User/selectors'
import { ApplicationState } from '../store/reducers'

import { User, UserType } from '../types/models/User'

import { isEmpty } from '../utils'
import { SKIP_DONATE_KEY } from '../constants/localStorage'

import { Twitter, Linkedin, Reddit, Facebook, Mail } from 'react-social-sharing'


type Props = {
  currentUser: User | undefined
}

const Dashboard: React.FC<Props> = ({ currentUser }) => {
  const [scheduleOpen, setScheduleOpen] = useState(false)
  const skipDonate = localStorage.getItem(SKIP_DONATE_KEY)

  if (!currentUser) return <Redirect to="/" />

  if (currentUser.type === UserType.normal && !skipDonate)
    return <Redirect to="/sign-up/donate" />

  return (
    <div className={styles.dashboard} data-layout="column">
      <div
        className={styles.mainSection}
        data-layout="column u4 middle align-center"
      >
        {currentUser && currentUser.type === UserType.pioneer ? (
          <Fragment>
            <h1 style={{ marginTop:"140px"}}>Congrats on becoming a Pioneer!</h1>
            
            <div className={styles.stayTuned} style={{ marginTop:"0px" }}>
              Thank you for your donation. We appreciate your help. As a Pioneer and our early supporter, you reserved a <strong>FREE</strong>  Premium membership on the HumanAct platform. You will also be one of the first ones to test our product when released. Stay tuned. We’ll keep you informed of the product updates.
            </div>
            
            <h1>Spread the word.</h1>
            <p style={{ marginTop:"0px", fontSize: "14px"}}>Help HumanAct build a powerful movement.</p>
            <div style={{ marginTop: "25px" }}>
              <Twitter solid small message="I just became a Pioneer on @_HumanAct. You should too. Take a seat at the table. Donate coffee money and help HumanAct build a powerful movement." link="https://humanact.com"/>
              <Linkedin solid small message="I just became a Pioneer on HumanAct. You should too. Take a seat at the table. Donate coffee money and help HumanAct build a powerful movement." link="https://humanact.com"/>
              <Reddit solid small link="https://humanact.com"/>
              <Facebook solid small message="I just became a Pioneer on HumanAct. You should too. Take a seat at the table. Donate coffee money and help HumanAct build a powerful movement." link="https://humanact.com"/>
              <Mail solid small subject="HumanAct - Take a seat at the table." link="I just became a Pioneer on HumanAct. You should too. Take a seat at the table. Donate coffee money and help HumanAct build a powerful movement. https://humanact.com"/>
            </div>
            

          </Fragment>
        ) : (
          <Fragment>
            <h1>Congratulations! You are on the list.</h1>
            <div className={styles.stayTuned}>
              We appreciate your interest in the HumanAct project. Stay tuned.
              We’ll let you know when we aproach the full release of the
              product.
            </div>

            <h1>Spread the word.</h1>
            <p style={{ marginTop:"0px", fontSize: "14px"}}>Help HumanAct build a powerful movement.</p>
            <div style={{ marginTop: "25px" }}>
              <Twitter solid small message="I just became a Pioneer on @_HumanAct. You should too. Take a seat at the table. Donate coffee money and help HumanAct build a powerful movement." link="https://humanact.com"/>
              <Linkedin solid small message="I just became a Pioneer on HumanAct. You should too. Take a seat at the table. Donate coffee money and help HumanAct build a powerful movement." link="https://humanact.com"/>
              <Reddit solid small link="https://humanact.com"/>
              <Facebook solid small message="I just became a Pioneer on HumanAct. You should too. Take a seat at the table. Donate coffee money and help HumanAct build a powerful movement." link="https://humanact.com"/>
              <Mail solid small subject="HumanAct - Take a seat at the table." link="I just became a Pioneer on HumanAct. You should too. Take a seat at the table. Donate coffee money and help HumanAct build a powerful movement. https://humanact.com"/>
            </div>
          </Fragment>
        )}
      </div >
      <div style={{ paddingTop:"120px" }}>
        {<ScheduleSection className={styles.schedule} />}
      </div>
    </div>
  )
}

const mapStateToProps = (state: ApplicationState) => ({
  currentUser: getUser(state)
})

export default connect(
  mapStateToProps,
  null
)(Dashboard)
