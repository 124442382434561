import React from 'react'

import styles from './TagLine.module.scss'

import { joinClasses } from '../../utils'

export type Props = {
  tags?: string[]
  className?: string
}

const TagLine: React.FC<Props> = ({
  tags = ['Share a thought', 'Start a movement', 'Make an impact'],
  className
}) => {
  return (
    <div className={joinClasses(styles.tagLine, className)}>
      <ul data-layout="row u2">
        {tags && tags.map(tag => <li key={tag}>{tag}</li>)}
      </ul>
    </div>
  )
}

export default TagLine
