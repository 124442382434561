import { matchPath } from 'react-router'

import Landing from '../pages/Landing'
import ContactUs from '../pages/ContactUs'
import CookiePolicy from '../pages/CookiePolicy'
import Donate from '../pages/Donate'
import DonateSuccess from '../pages/DonateSuccess'
import DonateFailure from '../pages/DonateFailure'
import TermsOfService from '../pages/TermsOfService'
import GetInvolved from '../pages/GetInvolved'
import Mission from '../pages/Mission'
import SignUp from '../pages/SignUp/SignUp'
import { Option } from '../types/Option'
import SignUpPreferences from '../pages/SignUp/SignUpPreferences'
import SignUpTopics from '../pages/SignUp/SignUpTopics'
import SignUpCountries from '../pages/SignUp/SignUpCountries'
import SignUpEmail from '../pages/SignUp/SignUpEmail'
import SignUpActivated from '../pages/SignUp/SignUpActivated'
import SignUpDonate from '../pages/SignUp/SignUpDonate'
import Dashboard from '../pages/Dashboard'
import ForgotPassword from '../pages/ForgotPassword'
import ResetPassword from '../pages/ResetPassword'
import ForgotPasswordEmail from '../pages/ForgotPasswordEmail'

type RouteConfig = {
  authRequired?: boolean
  path: string
  exact?: boolean
  component: React.ComponentType<any>
  headerType: 'light' | 'dark' | 'mixed'
  headerSticky?: boolean
  headerHideRight?: boolean
}

export const routes: RouteConfig[] = [
  { path: '/', exact: true, component: Landing, headerType: 'mixed' },
  {
    path: '/dashboard',
    component: Dashboard,
    headerType: 'dark',
    authRequired: false
  },
  { path: '/our-mission', component: Mission, headerType: 'dark' },
  { path: '/contact-us', component: ContactUs, headerType: 'dark' },
  {
    path: '/forgot-password/email',
    component: ForgotPasswordEmail,
    exact: true,
    headerType: 'dark'
  },
  {
    path: '/forgot-password',
    component: ForgotPassword,
    headerType: 'dark',
    exact: true
  },
  { path: '/reset-password', component: ResetPassword, headerType: 'dark' },
  {
    path: '/donate',
    component: Donate,
    headerType: 'dark',
    headerSticky: false,
    headerHideRight: true
  },
  {
    path: '/donate-success',
    component: DonateSuccess,
    headerType: 'dark',
    headerHideRight: true
  },
  {
    path: '/donate-failure',
    component: DonateFailure,
    headerType: 'dark',
    headerHideRight: true
  },
  {
    path: '/terms-of-service',
    component: TermsOfService,
    headerType: 'dark'
  },
  {
    path: '/cookie-policy',
    component: CookiePolicy,
    headerType: 'dark',
    //headerSticky: false
  },
  {
    path: '/get-involved',
    component: GetInvolved,
    headerType: 'dark',
    headerSticky: false
  },
  {
    path: '/sign-up',
    component: SignUp,
    headerType: 'dark',
    exact: true
  },
  {
    path: '/sign-up/preferences',
    component: SignUpPreferences,
    headerType: 'dark',
    headerHideRight: true,
    exact: true
  },
  {
    path: '/sign-up/topics',
    component: SignUpTopics,
    headerType: 'dark',
    headerHideRight: true,
    exact: true
  },
  {
    path: '/sign-up/countries',
    component: SignUpCountries,
    headerType: 'dark',
    headerHideRight: true,
    exact: true
  },
  {
    path: '/sign-up/email',
    component: SignUpEmail,
    headerType: 'dark',
    headerHideRight: true,
    exact: true
  },
  {
    path: '/sign-up/activate',
    component: SignUpActivated,
    headerType: 'dark',
    headerHideRight: true,
    exact: true
  },
  {
    path: '/sign-up/donate',
    component: SignUpDonate,
    headerType: 'dark',
    headerHideRight: true,
    exact: true
  }
]

export function getRouteForPathname(pathname: string): Option<RouteConfig> {
  const config = routes.find(({ path, exact }) =>
    matchPath(pathname, { path, exact })
  )
  return config ? Option.Some(config) : Option.None()
}
