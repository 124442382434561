import { importSvg as iSvg } from '../../utils'

const importSvg = (path: string) => iSvg('icons/issues/' + path)

export const icons = {
  climateChange: importSvg('global-warming.svg'),
  agriculture: importSvg('agriculture.svg'),
  energy: importSvg('clean-energy.svg'),
  water: importSvg('clean-water-and-sanitation.svg'),
  genderEquality: importSvg('gender-equality.svg'),
  peace: importSvg('peace.svg'),
  justice: importSvg('justice.svg'),
  education: importSvg('education.svg'),
  health: importSvg('health-and-well-being.svg'),
  inequalities: importSvg('inequalities.svg'),
  poverty: importSvg('poverty.svg'),
  technology: importSvg('tech-and-innovation.svg'),
  citiesCommunities: importSvg('sustainable-cities.svg'),
  consumptionProduction: importSvg('consumption-and-production.svg'),
  ecology: importSvg('ecology.svg'),
  economy: importSvg('economy.svg'),
  corruption: importSvg('corruption.svg'),
  disasterRelief: importSvg('disaster-relief.svg'),
  immigration: importSvg('immigration.svg'),
  infrastructure: importSvg('infrastructure.svg')
}

export const getIconFromNameKey = (key: string) => {
  const iconKey = key.split('.')[1] as keyof typeof icons

  if (!icons[iconKey]) {
    console.error(`[ISSUES ICON]] Icon: ${iconKey} is missing.`)
  }

  return icons[iconKey]
}
