import { api } from '../apiConfig'
import { decodeServerResponse } from '../utils'
import { authLoginDecoder, authRegisterDecoder } from '../../types/models/Auth'

export async function login(email: string, password: string) {
  const response = await api.post('/login', { email, password })
  return decodeServerResponse(response).andThen(v => {
    return authLoginDecoder.decode(v.data)
  })
}

export async function register(
  email: string,
  password: string,
  fullName: string
) {
  const response = await api.post('/register', { email, password, fullName })
  return decodeServerResponse(response).andThen(v => {
    return authRegisterDecoder.decode(v.data)
  })
}

export async function activateAccount(token: string) {
  return api.post('/verify-register', { token })
}

export async function forgotPassword(email: string) {
  return api.post('/forgot-password', { email })
}

export async function resetPassword(payload: {
  email: string
  token: string
  password: string
}) {
  return api.post('/reset-password', payload)
}
