import React from 'react'
import styles from './ReleaseSchedule.module.scss'

const ReleaseSchedule: React.FC = ({ children }) => {
  return (
    <div className={styles.releaseSchedule} data-layout="column">
      {children}
    </div>
  )
}

export default ReleaseSchedule
