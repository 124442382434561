import React, { useState, useEffect, Fragment } from 'react'
import { FormikProps, withFormik, FieldArray } from 'formik'
import * as Yup from 'yup'

import styles from './SignUpCountries.module.scss'
import shared from './SignUpShared.module.scss'

import PageHeading from '../../components/PageHeading'
import CountrySelect from '../../components/shared/CountrySelect'

import { getCountryList } from '../../api/public/Country'
import { saveCountriesNationalities } from '../../api/user/Profile'

import { Country } from '../../types/models/Country'

import history from '../../services/History'

type Props = {}

type FormProps = {
  countries: number[]
  nationalities: number[]
}

const SignUpCountries: React.FC<Props & FormikProps<FormProps>> = ({
  values,
  handleSubmit,
  setFieldValue,
  isValid,
  isSubmitting
}) => {
  const [countries, setCountries] = useState<Country[]>([])

  useEffect(() => {
    getCountryList().then(countries => countries.onOk(setCountries))
  }, [])

  return (
    <div className={styles.signUpCountries} data-layout="column align-center">
      <PageHeading
        title="What country do you represent?"
        description="Tell us what part of the World do you want to influence the most."
      />
      <form data-layout="column u05" onSubmit={handleSubmit}>
        <FieldArray
          name="countries"
          render={arrayHelpers => {
            return (
              <Fragment>
                {values.countries.map((val, index) => (
                  <CountrySelect
                    key={index}
                    countries={countries}
                    placeholder="Country of residence"
                    alwaysShowRemove={index > 0}
                    handleClear={() => {
                      index > 0 && arrayHelpers.remove(index)
                    }}
                    handleChange={country =>
                      setFieldValue(
                        `countries.${index}`,
                        country ? country.id : -1
                      )
                    }
                  />
                ))}
                <div
                  className={styles.addMore}
                  onClick={() => arrayHelpers.push(-1)}
                >
                  + Add another country
                </div>
              </Fragment>
            )
          }}
        />
        <FieldArray
          name="nationalities"
          render={arrayHelpers => {
            return (
              <Fragment>
                {values.nationalities.map((val, index) => (
                  <CountrySelect
                    key={index}
                    countries={countries}
                    displayNationality
                    alwaysShowRemove={index > 0}
                    handleClear={() => {
                      index > 0 && arrayHelpers.remove(index)
                    }}
                    dropdownTitle="Select nationality"
                    placeholder="Nationality"
                    handleChange={country =>
                      setFieldValue(
                        `nationalities.${index}`,
                        country ? country.id : -1
                      )
                    }
                  />
                ))}
                <div
                  className={styles.addMore}
                  onClick={() => arrayHelpers.push(-1)}
                >
                  + Add another nationality
                </div>
              </Fragment>
            )
          }}
        />{' '}
        <button disabled={!isValid || isSubmitting} style={{ width: '270px' }}>
          Continue
        </button>
        <span className={shared.notice}>
          Don’t worry you can always change it later.
        </span>
      </form>
      <div className={shared.step}>Step 3 of 4</div>
    </div>
  )
}

export default withFormik<Props, FormProps>({
  mapPropsToValues: () => ({
    countries: [-1],
    nationalities: [-1]
  }),
  validationSchema: Yup.object().shape({
    nationalities: Yup.array()
      .transform(e => e.filter((e: number) => e > 0))
      .of(Yup.number().min(0))
      .min(1),
    countries: Yup.array()
      .transform(e => e.filter((e: number) => e > 0))
      .of(Yup.number().min(0))
      .min(1)
  }),
  async handleSubmit({ countries, nationalities }) {
    try {
      await saveCountriesNationalities(
        countries.filter(e => e > -1),
        nationalities.filter(e => e > -1)
      )
      history.push('/sign-up/email')
    } catch (error) {
      console.error(error)
    }
  }
})(SignUpCountries)
