import React from 'react'
import styles from './ContactUs.module.scss'

import ContactUsBg from '../assets/images/about_us_hero_01.png'

const ContactUs: React.FC = () => {
  return (
    <div className={styles.contactUs}>
      <div
        className={styles.hero}
        data-layout="column u2 middle align-center"
        style={{ backgroundImage: `url(${ContactUsBg})` }}
      >
        <div className={styles.tagLine}>Don't be afraid to speak up</div>
        <h1>Contact Us</h1>
      </div>
      <div className={styles.contacts}>
        <div className={styles.contactBlock} style={{ maxWidth: '170px' }}>
          <h1>Press Inquiries</h1>
          <a href="mailto: press@humanact.com" className={styles.mail}>
            press@humanact.com
          </a>
        </div>
        <div className={styles.contactBlock}>
          <h1>Ideas, Comments, or Concerns?</h1>
          <a href="mailto: support@humanact.com" className={styles.mail}>
            support@humanact.com
          </a>
        </div>
        <div className={styles.contactBlock}>
          <h1>Movement Inquiries</h1>
          <a href="mailto: ove@humanact.com" className={styles.mail}>
            move@humanact.com
          </a>
        </div>
        <div className={styles.contactBlock}>
          <h1>Donations or Investments</h1>
          <a href="mailto: friends@humanact.com" className={styles.mail}>
            friends@humanact.com
          </a>
        </div>
        <div className={styles.contactBlock}>
          <h1>Advisory Inquiries</h1>
          <a href="mailto: advise@humanact.com" className={styles.mail}>
            advise@humanact.com
          </a>
        </div>
      </div>
    </div>
  )
}

export default ContactUs
