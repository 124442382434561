import { Reducer } from '../types'
import { UiActionType } from './actionTypes'

export interface UiState {
  data: {
    signInModalVisible: boolean
    menuOpen: boolean
  }
}

const initialState: UiState = {
  data: {
    signInModalVisible: false,
    menuOpen: false
  }
}

const UIReducer: Reducer<UiState, UiActionType> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case 'UPDATE_SIGNIN_MODAL_VISIBILITY': {
      return {
        ...state,
        data: {
          ...state.data,
          signInModalVisible: !state.data.signInModalVisible
        }
      }
    }
    case 'SET_MENU_OPEN': {
      return {
        ...state,
        data: {
          ...state.data,
          menuOpen: action.payload.value
        }
      }
    }

    default:
      return state
  }
}

export default UIReducer
