import React from 'react'
import { RouteComponentProps } from 'react-router'

import styles from './SignUp.module.scss'

import PageHeading from '../../components/PageHeading'
import SignUpForm from '../../components/SignUpForm'
import SeatsLeft from '../../components/SeatsLeft'

export type StateProps = {
  fullName: string
  email: string
}

const SignUp: React.FC<RouteComponentProps<{}, {}, StateProps>> = ({
  location
}) => {
  return (
    <div className={styles.signUp} data-layout="column align-center u4">
      <PageHeading
        title="Take a seat at the table"
        prepend="We’re just getting started"
        description="Tell us about yourself to become one of our pioneer members."
      />
      <SignUpForm
        full
        email={location.state && location.state.email}
        fullName={location.state && location.state.fullName}
      />
      <SeatsLeft className={styles.seatsLeft} inline text="Seats left" />
    </div>
  )
}

export default SignUp
