import React from 'react'
import styles from './DropdownMenu.module.scss'
import { joinClasses } from '../utils'
import { useTransition, animated } from 'react-spring'

type Props = {
  className?: string
  open: boolean
  header: JSX.Element
  buttons: JSX.Element[]
}

const DropdownMenu: React.FC<Props> = ({
  className,
  header,
  buttons,
  open
}) => {
  const transitions = useTransition(open, null, {
    from: { position: 'absolute', opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: {
      tension: 300
    }
  })

  return (
    <>
      {transitions.map(
        ({ item, key, props }) =>
          item && (
            <animated.div
              key={key}
              style={props}
              className={joinClasses(styles.dropdownMenu, className)}
            >
              <div className={styles.header}>{header}</div>
              <div className={styles.buttons}>{buttons}</div>
            </animated.div>
          )
      )}
    </>
  )
}

export default DropdownMenu
