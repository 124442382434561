import * as Yup from 'yup'

export const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email()
    .required(),
  amount: Yup.number().min(1),
  firstName: Yup.string()
    .required()
    .min(3),
  lastName: Yup.string()
    .required()
    .min(3),
  comment: Yup.string()
    .required()
    .max(300),
  billingFirstName: Yup.string().required(),
  billingLastName: Yup.string().required(),
  address: Yup.string().required(),
  captcha: Yup.string().required(),
  cardNumber: Yup.boolean().oneOf([true]),
  cardExpiry: Yup.boolean().oneOf([true]),
  cardCvc: Yup.boolean().oneOf([true])
})
