import React from 'react'
import LandingSection from '../LandingSection'

import { CONTENT } from './ScheduleSection.content'
import { ReleaseSchedule, Stage, Milestone } from '../shared/releaseSchedule'

export type Props = {
  className?: string
}

const ScheduleSection: React.FC<Props> = ({ className }) => {
  const milestonesList = CONTENT.stages.flatMap(stage => stage.milestones)

  return (
    <LandingSection
      layout="column"
      className={className}
      title={<h1>Release schedule.</h1>}
      description={({ className }) => (
        <div className={className}>HumanAct Roadmap</div>
      )}
    >
      <ReleaseSchedule>
        {CONTENT.stages.map(stage => (
          <Stage name={stage.name} key={stage.name}>
            {stage.milestones.map(milestone => {
              const globalIdx = milestonesList.findIndex(m => m === milestone)
              const isLast = globalIdx === milestonesList.length - 1
              const isNextDone = !isLast && milestonesList[globalIdx + 1].done
              return (
                <Milestone
                  key={milestone.name}
                  name={milestone.name}
                  description={milestone.description}
                  date={milestone.date}
                  done={milestone.done}
                  endCap={isLast}
                  isNextDone={isNextDone}
                />
              )
            })}
          </Stage>
        ))}
      </ReleaseSchedule>
    </LandingSection>
  )
}

export default ScheduleSection
