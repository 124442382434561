import React from 'react'
import styles from './JoinUsWidget.module.scss'
import { FormikProps, withFormik } from 'formik'
import * as Yup from 'yup'
import { signUpForNewsletter } from '../api/public/Newsletter'

type FormProps = { email: string }
type FormStatus = { success: boolean; message: string; error: string | null }

const JoinUsWidget: React.FC<FormikProps<FormProps>> = ({
  values,
  errors,
  touched,
  status,
  handleBlur,
  handleChange,
  handleSubmit
}) => {
  // FIXME: temporary type cast, remove after following issue is closed
  // https://github.com/jaredpalmer/formik/issues/1334
  const { success, message, error } =
    status || ({ success: false, message: '', error: null } as FormStatus)

  const Form = (
    <>
      <form
        className={styles.inputLine}
        data-layout="row u1"
        onSubmit={handleSubmit}
      >
        <input
          className={touched.email && !!errors.email ? 'invalid' : ''}
          type="email"
          name="email"
          value={values.email}
          placeholder="Enter email"
          onChange={handleChange}
          onBlur={handleBlur}
        />
        <button className="ghost" type="submit">
          Submit
        </button>
      </form>
      {error && <div className={styles.error}>{error}</div>}
      <div className={styles.description}>
        When you sign up you’ll become a member of HumanAct activist network. We
        will keep you informed with the latest alerts and progress reports.
      </div>
    </>
  )

  const SignUpSuccessMsg = (
    <div className={styles.signUpSuccess}>
      You have successfully signed up to our Newsletter mailing list.
    </div>
  )

  return (
    <div className={styles.joinUsWidget}>
      <h1>Join Us</h1>
      {success ? SignUpSuccessMsg : Form}
    </div>
  )
}

export default withFormik<{}, FormProps>({
  mapPropsToValues: () => ({ email: '' }),
  validateOnBlur: false,
  validateOnChange: false,
  validationSchema: Yup.object().shape({
    email: Yup.string()
      .email()
      .required()
  }),
  handleSubmit: ({ email }, { setStatus, setError }) => {
    signUpForNewsletter(email)
      .then(res => {
        res.onOk(({ message, data }) => {
          setStatus({ success: true, message, error: null } as FormStatus)
        })
      })
      .catch(res => {
        setStatus({
          success: false,
          message: res.response.data.message,
          error: res.response.data.message
        } as FormStatus)
      })
  }
})(JoinUsWidget)
