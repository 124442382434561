import {
  numberDecoder,
  stringDecoder,
  optional,
  objectDecoder,
  Decoder
} from '../Decoder'

export interface Topic {
  id: number
  name: string
  nameTranslationKey: string
  createdAt: string
  deletedAt?: string
}

export const topicDecoder: Decoder<unknown, Topic> = objectDecoder({
  id: numberDecoder,
  name: stringDecoder,
  nameTranslationKey: stringDecoder,
  createdAt: stringDecoder,
  deletedAt: optional(stringDecoder)
})
