import React, { useEffect, useState } from 'react'
import styles from './SeatsLeft.module.scss'
import { seatsLeft } from '../api/public/Stats'
import { joinClasses } from '../utils'

const TOTAL = 250000

export type Props = {
  inline?: boolean
  left?: number
  text?: string
  className?: string
  showCount?: boolean
}

const displayNumber = (number: number) => {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

const SeatsLeft: React.FC<Props> = ({
  inline = false,
  text = 'Pioneer seats left',
  className,
  left,
  showCount = true
}) => {
  const [seats, setSeats] = useState(TOTAL)
  const progress = Math.max((TOTAL - seats) / TOTAL, 0.05)

  useEffect(() => {
    if (left === undefined) {
      seatsLeft().then(seats => seats.onOk(stats => setSeats(stats.seatsLeft)))
    } else {
      setSeats(left)
    }
  }, [left])

  return (
    <div
      className={joinClasses(
        styles.seatsLeft,
        inline && styles.seatsLeftInline,
        className
      )}
      data-layout={inline ? 'row align-center u2' : 'column align-center u3'}
    >
      {showCount && (
        <p className={styles.line}>
          <span>{displayNumber(seats)}</span> {text}
        </p>
      )}
      <div className={styles.counter}>
        <div
          className={styles.used}
          style={{ width: `${progress * 100}%` }}
        ></div>
      </div>
    </div>
  )
}

export default SeatsLeft
