import React, { ReactNode, Fragment, PureComponent } from 'react'
import { connect } from 'react-redux'

import { requestUserFetch } from '../store/User/actions'

export type Props = {
  children: ReactNode
  isLoggedIn: boolean
  requestUserFetch: typeof requestUserFetch
}

class UserProvider extends PureComponent<Props> {
  componentDidMount() {
    const { isLoggedIn, requestUserFetch } = this.props
    const token = localStorage.getItem('token')

    if (!isLoggedIn && token) {
      requestUserFetch()
    }
  }

  render() {
    return <Fragment>{this.props.children}</Fragment>
  }
}

const mapStateToProps = (state: any) => ({
  isLoggedIn: !!state.user.data.user
})

const mapDispatchToProps = {
  requestUserFetch
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserProvider)
