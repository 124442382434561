import React, { ReactNode } from 'react'
import AriaModal from 'react-aria-modal'

import styles from './Modal.module.scss'

import { CloseIcon } from '../assets/icons'

export type Props = {
  children?: ReactNode
  handleClose?: () => void
}

const Modal: React.FC<Props> = ({ children, handleClose }) => {
  const getApplicationNode = () => {
    return document.getElementById('app') as Element
  }

  return (
    <AriaModal
      titleText="modal"
      initialFocus="#modal"
      onExit={handleClose}
      getApplicationNode={getApplicationNode}
      underlayStyle={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: 'rgba(255, 255, 255, .9)'
      }}
      dialogClass={styles.ModalDialog}
    >
      <div
        id="modal"
        data-layout="column middle align-center"
        className={styles.ModalBody}
      >
        <div onClick={handleClose} className={styles.ModalCloseIcon}>
          <CloseIcon></CloseIcon>
        </div>
        {children}
      </div>
    </AriaModal>
  )
}

export default Modal
