import React from 'react'
import { Elements } from 'react-stripe-elements'
import { Link, Redirect } from 'react-router-dom'

import shared from './SignUpShared.module.scss'
import styles from './SignUpDonate.module.scss'

import PageHeading from '../../components/PageHeading'
import SignUpDonateForm from './SignUpDonate.form'
import Accordion from '../../components/Accordion'
import AccordionItem from '../../components/AccordionItem'

import { DONATE_FAQ } from '../../constants/faq'

import { joinClasses } from '../../utils'
import { SKIP_DONATE_KEY } from '../../constants/localStorage'
import { useSelector } from 'react-redux'
import { getUser } from '../../store/User/selectors'
import { UserType } from '../../types/models/User'

const SignUpDonate: React.FC = () => {
  const saveSkipDonate = () => {
    localStorage.setItem(SKIP_DONATE_KEY, 'skip')
  }

  const user = useSelector(getUser)

  if (user && user.type === UserType.pioneer)
    return <Redirect to="/dashboard" />

  return (
    <div className={styles.signUpDonate} data-layout="column align-center">
      <PageHeading
        title="Become a pioneer member"
        description="Make a donation now to save a seat at the HumanAct Pioneers’ Table. Your contribution will help us start this new revolution."
      />
      <div className={styles.formWrapper}>
        <Elements>
          <SignUpDonateForm />
        </Elements>
      </div>
      <Link
        to="/dashboard"
        onClick={saveSkipDonate}
        className={joinClasses('button', 'ghost', styles.skip)}
      >
        No, thanks
      </Link>
      <div className={shared.notice}>Put me on the waiting list instead.</div>
      <div className={shared.step}>Step 4 of 4</div>
      <section className={styles.faqSection} data-layout="column align-center">
        <h1 className={styles.sectionHeading}>Frequently Asked Questions</h1>

        <Accordion className={styles.faq}>
          {DONATE_FAQ.map((data, index) => (
            <AccordionItem title={data.title} key={index}>
              {data.content}
            </AccordionItem>
          ))}
        </Accordion>
      </section>
    </div>
  )
}

export default SignUpDonate
