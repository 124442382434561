export const CONTENT = {
  stages: [
    {
      name: 'Pioneer',
      milestones: [
        {
          name: 'Early Release',
          description: 'Pre-Launch HumanAct.com Website Release',
          date: 'October 2019',
          done: true
        },
        {
          name: 'Pioneer Member Sign Up',
          description:
            'Pool of 250,000 Pioneer Users that will have early access to new features before beta release and get Premium account status.',
          date: 'November 2019',
          done: true
        },
        {
          name: 'Movement Creation',
          description:
            'Easily create a profile of your movement and grow your support network. Launch campaigns, start a fundraiser, and join the constructive conversation.',
          date: 'Q2 2020',
          done: false
        },
        {
          name: 'Feedback Tools',
          description:
            'Add constructive questions to your posts, listen to your supporters and get access to impactful data on a global and local stage.',
          date: 'Q2 2020',
          done: false
        }
      ]
    },
    {
      name: 'Launch Beta',
      milestones: [
        {
          name: 'Go Live',
          description: 'Release of the Beta version of the platform.',
          date: 'Q2 2020',
          done: false
        },
        {
          name: 'Public Officials Library',
          description:
            'Release of Public Officials profiles in the US and EU. View sentiment data of HumanAct users and mesuare impact of your movements.',
          date: 'Q3 2020',
          done: false
        }
      ]
    }
  ]
}
