import React, { useEffect, useState, useRef } from 'react'
import { Link } from 'react-router-dom'

import styles from './GetInvolved.module.scss'

import PageHeading from '../components/PageHeading'
import SeatsLeft from '../components/SeatsLeft'
import TagLine from '../components/shared/TagLine'
import Stats from '../components/shared/Stats'
import Accordion from '../components/Accordion'
import AccordionItem from '../components/AccordionItem'

import { seatsLeft } from '../api/public/Stats'
import { joinClasses, useScrollState } from '../utils'

import { SeatsLeftModel } from '../types/models/Stats'
import Issues from '../components/shared/issues/Issues'

import { LANDING_FAQ } from '../constants/faq'

const GetInvolved: React.FC = () => {
  const [stats, setSeats] = useState<SeatsLeftModel>({
    actionsTakenToSupport: 0,
    preLaunchUsers: 0,
    seatsLeft: 0
  })
  const sticky = useRef<HTMLDivElement>(null)
  const scrolled = useScrollState({
    breakpoint: sticky.current !== null ? sticky.current.offsetTop : 0
  })

  useEffect(() => {
    seatsLeft().then(seats => seats.onOk(setSeats))
  }, [])

  return (
    <div className={styles.getInvolved}>
      <div className={styles.header} data-layout="column center align-center">
        <PageHeading
          prepend="Get involved"
          title="Take a seat at the table. Become a pioneer member."
        />
        <Link className={joinClasses(styles.button, 'button')} to="/sign-up">
          Sign up
        </Link>
        <SeatsLeft left={stats.seatsLeft} />
        <TagLine className={styles.tagLine} />
      </div>
      <div className={styles.stickyWrapper} ref={sticky}>
        <div
          className={joinClasses(
            styles.sticky,
            scrolled && styles.stickyActive
          )}
          data-layout="column"
          data-layout-md="row between"
        >
          <div
            className={styles.stickyLeft}
            data-layout="row middle align-center"
          >
            Become one of our pioneer members
          </div>
          <div className={styles.stickyRight} data-layout="row u3 between">
            <SeatsLeft inline text="Seats left" />
            <Link
              className={joinClasses(
                'button',
                'white',
                'whiteBorder',
                'medium'
              )}
              to="/sign-up"
            >
              Sign up
            </Link>
          </div>
        </div>
      </div>
      <div className={styles.statsLine} data-layout="column align-center">
        <Stats
          stats={[
            {
              count: stats.preLaunchUsers,
              description: 'Signed up\nto date'
            },
            {
              count: stats.actionsTakenToSupport,
              description: 'Early Pioneer \nSupporters'
            },
            {
              count: stats.seatsLeft,
              description: 'Pioneer seats \navailable'
            }
          ]}
        />
      </div>
      <section className={styles.section} data-layout="column align-center">
        <h1 className={styles.sectionHeading}>
          Demand a more sustainable future.
        </h1>
        <p className={styles.sectionDescription}>
          Join the movement and accept nothing less than a socially responsible
          future. Be a part of HumanAct and be the change we all want to see.
        </p>
        <div className={styles.issuesWrapper}>
          <Issues />
        </div>
      </section>
      <section className={styles.section} data-layout="column align-center">
        <h1 className={styles.sectionHeading}>
          Everything counts. What you do, counts.
        </h1>
        <p className={styles.sectionDescription}>
          Help us build a platform for global societal transformation. Become one of our early supporters.
        </p>
        <Link
          to="/donate"
          className={joinClasses('button', styles.button)}
        >
          Donate Now
        </Link>
        <div style={{ maxWidth: '800px', overflow: 'hidden', marginTop: '-30px', marginBottom: '0px', textAlign: 'center' }}>
        
        </div>
        
      </section>
      <section className={styles.section} data-layout="column align-center">
        <h1 className={styles.sectionHeading}>Why become a pioneer?</h1>

        <Accordion className={styles.faq}>
          {LANDING_FAQ.map((data, index) => (
            <AccordionItem title={data.title} key={index}>
              {data.content}
            </AccordionItem>
          ))}
        </Accordion>
      </section>
    </div>
  )
}

export default GetInvolved
