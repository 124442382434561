export type Content = {
  title: string
  subtitle?: string
  content: string
}

export const CONTENT: Content[] = [
  {
    title: 'HumanAct Cookie Policy',
    //subtitle: 'Welcome to HumanAct!',
    content:
      'This site and the HumanAct platform may use cookies. This page describes how and why we use cookies. If you have any questions about the Cookie Policy, you may contact us by email support@humanact.com.'
  },
  {
    title: 'How We Use Cookies',
    content:
      'We use cookies for a variety of reasons detailed below. Unfortunately in most cases there are no industry standard options for disabling cookies without completely disabling the functionality and features they add to this site. It is recommended that you leave on all cookies if you are not sure whether you need them or not in case they are used to provide a service that you use.'
  },
  {
    title: 'Disabling Cookies',
    content:
      'You can prevent the setting of cookies by adjusting the settings on your browser (see your browser Help for how to do this). Be aware that disabling cookies will affect the functionality of this and many other websites that you visit. Disabling cookies will usually result in also disabling certain functionality and features of this site. Therefore, it is recommended that you do not disable cookies. The Cookie Consent tool can be utilized to customize your cookie preferences. The tool will record when you have consented to our cookie policy and will ask for consent periodically to ensure users stay up-to-date with changes to our cookie and privacy policies. The consent tool specifically controls the marketing cookies and analytical cookies set by using the HumanAct website. Essential cookies cannot be disabled. '
  },
  {
    title: 'Account related cookies',
    content: `If you create an account with us, then we will use cookies for the management of the signup process and general administration. These cookies will usually be deleted when you log out; however, in some cases they may remain afterwards to remember your site preferences when logged out.`
  },
  {
    title: 'Login related cookies',
    content: `We use cookies when you are logged in so that we can remember that you are logged in. This prevents you from having to log in every single time you visit a new page. These cookies are typically removed or cleared when you log out to ensure that you can only access restricted features and areas when logged in.`
  },
  {
    title: 'Email newsletters related cookies',
    content: `This site offers newsletter or email subscription services and cookies may be used to remember if you are already registered and whether to show certain notifications which might only be valid to subscribed/unsubscribed users.`
  },
  {
    title: 'Forms related cookies',
    content: `When you submit data to us through a form such as those found on contact pages or comment forms, cookies may be set to remember your user details for future correspondence.`
  },
  {
    title: 'Third Party Cookies',
    content: `We use only a few third party cookies.  We use Facebook, LinkedIn, and Google's pixels to track our marketing efforts.`
  },
  {
    title: 'What information do we know about you?',
    content: `We don’t request or require you to provide personal information to access our web site. As noted above, we may receive your IP address and user agent automatically. If you optionally elect to fill out our early access request form, we will receive your Name, Email, Phone number, and Company or Organization name as well as your stated interest in our products.`
  },
  {
    title: 'What about cookies?',
    content: `The HumanAct website and platform may place cookies on your browser in order to identify you when you return to our website or log in to our platform.`
  }
]
