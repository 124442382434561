import React from 'react'
import styles from './Footer.module.scss'
import { Logo } from '../assets'
import BottomNavSection from './BottomNavSection'
import { Link } from 'react-router-dom'

const Footer: React.FC = () => {
  return (
    <div className={styles.footer}>
      <main data-layout="column u4" data-layout-md="row u2">
        <Logo className={styles.logo} />
        <div className={styles.bottomNav}>
          <BottomNavSection name="Company">
            <Link to="/our-mission">Mission</Link>
            <Link to="/get-involved">Get Involved</Link>
            <Link to="/donate">Donate</Link>
          </BottomNavSection>
          <BottomNavSection name="Community">
            <Link to="/contact-us">Contact us</Link>
            <Link to="/terms-of-service">Terms &amp; Privacy Policy</Link>
            <Link to="/cookie-policy">Cookie Policy</Link>
          </BottomNavSection>
          <BottomNavSection name="Follow us">
            <a href="https://www.instagram.com/_humanact/">Instagram</a>
            <a href="https://www.linkedin.com/company/humanact-inc/">
              LinkedIn
            </a>
            <a href="https://twitter.com/_HumanAct ">Twitter</a>
          </BottomNavSection>
        </div>
      </main>
      <div className={styles.copyright}>
        Copyright © 2020 HumanAct Inc. All rights reserved.
      </div>
    </div>
  )
}

export default Footer
