import { api } from '../apiConfig'
import { decodeServerResponse } from '../utils'
import { arrayDecoder } from '../../types/Decoder'
import { topicDecoder } from '../../types/models/Topic'

export async function getTopicList() {
  const response = await api.get('/topics')
  const topicListDecoder = arrayDecoder(topicDecoder)
  return decodeServerResponse(response).andThen(v => {
    return topicListDecoder.decode(v.data)
  })
}
