import React from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import { withFormik, FormikProps } from 'formik'

import DonationAmountChooser from './DonationAmountChooser'
import FormGroup from '../FormGroup'

import history from '../../services/History'

import styles from './DonateForm.module.scss'
import { validationSchema } from './DonateForm.schema'

import {
  CardNumberElement,
  CardExpiryElement,
  CardCVCElement,
  injectStripe,
  ReactStripeElements
} from 'react-stripe-elements'
import { donate } from '../../api/public/Donators'
import { StripeStyle } from '../../constants/stripe'

type FormProps = {
  email: string
  amount: number
  firstName: string
  lastName: string
  billingFirstName: string
  billingLastName: string
  comment: string
  address: string
  captcha: string
  cardExpiry: boolean
  cardCvc: boolean
  cardNumber: boolean
}

type Props = {
  stripe?: ReactStripeElements.StripeProps
  defaultAmount?: number
}

const DonateForm: React.FC<FormikProps<FormProps>> = ({
  setFieldValue,
  setFieldTouched,
  isSubmitting,
  values,
  errors,
  touched,
  handleChange,
  handleSubmit,
  handleBlur
}) => {
  const handleStripeInput = (name: keyof FormProps) => ({
    classes: {
      focus: styles.active,
      completed: styles.customInput,
      base: styles.customInput,
      invalid: styles.invalid
    },
    className:
      touched[name] && errors[name]
        ? `${styles.invalid} ${styles.customInput}`
        : styles.customInput,
    style: StripeStyle,
    onChange: (change: ReactStripeElements.ElementChangeResponse) => {
      setFieldValue(name, change.complete)
      setFieldTouched(name)
    }
  })

  return (
    <form className={styles.donateForm} onSubmit={handleSubmit}>
      <div className={styles.formSection}>
        <h2 className={styles.formSectionTitle}>Select an amount</h2>
        <DonationAmountChooser
          name="amount"
          handleChange={setFieldValue}
          amount={values.amount}
          alwaysShowCustom
        />
      </div>
      <div className={styles.formSection} data-layout="column u2">
        <h2 className={styles.formSectionTitle}>Your information</h2>
        <FormGroup label="Name">
          <input
            name="firstName"
            value={values.firstName}
            onBlur={handleBlur}
            onChange={handleChange}
            placeholder="First Name"
            className={touched.firstName && errors.firstName ? 'invalid' : ''}
          />
          <input
            name="lastName"
            value={values.lastName}
            onBlur={handleBlur}
            onChange={handleChange}
            placeholder="Last Name"
            className={touched.lastName && errors.lastName ? 'invalid' : ''}
          />
        </FormGroup>
        <FormGroup label="Email" column>
          <input
            name="email"
            type="email"
            value={values.email}
            onBlur={handleBlur}
            onChange={handleChange}
            placeholder="Enter email"
            className={touched.email && errors.email ? 'invalid' : ''}
          />
          <p className={styles.suffix}>Your receipt will be emailed here.</p>
        </FormGroup>
        <FormGroup label="Leave a comment" column padding="">
          <textarea
            name="comment"
            value={values.comment}
            onBlur={handleBlur}
            onChange={handleChange}
            placeholder="Let us know why you donated, tell your story, or send words of encouragment. Your comment will be private…"
            className={touched.comment && errors.comment ? 'invalid' : ''}
          />
          <p className={styles.characterCount}>{values.comment.length}/300</p>
        </FormGroup>
      </div>
      <div className={styles.formSection} data-layout="column u2">
        <h2 className={styles.formSectionTitle}>Payment information</h2>
        <FormGroup label="Name on the card">
          <input
            name="billingFirstName"
            value={values.billingFirstName}
            onBlur={handleBlur}
            onChange={handleChange}
            placeholder="First Name"
            className={
              touched.billingFirstName && errors.billingFirstName
                ? 'invalid'
                : ''
            }
          />
          <input
            name="billingLastName"
            value={values.billingLastName}
            onBlur={handleBlur}
            onChange={handleChange}
            placeholder="Last Name"
            className={
              touched.billingLastName && errors.billingLastName ? 'invalid' : ''
            }
          />
        </FormGroup>
        <FormGroup label="Card number">
          <CardNumberElement {...handleStripeInput('cardNumber')} />
        </FormGroup>
        <FormGroup label=" ">
          <CardExpiryElement {...handleStripeInput('cardExpiry')} />
          <CardCVCElement {...handleStripeInput('cardCvc')} />
        </FormGroup>
        <FormGroup label="Billing address">
          <input
            name="address"
            value={values.address}
            onBlur={handleBlur}
            onChange={handleChange}
            placeholder="Enter your billing address"
            className={touched.address && errors.address ? 'invalid' : ''}
          />
        </FormGroup>
        <div className={styles.recaptcha} data-layout="column align-center">
          <ReCAPTCHA
            sitekey="6LdHRK8UAAAAAFR1SRS7yfAogOPtdOknnz1EK5bJ"
            onChange={token => setFieldValue('captcha', token)}
          />
        </div>
      </div>
      <div
        className={`${styles.formSection} ${styles.paySection}`}
        data-layout="column align-center"
      >
        <div className={styles.paySectionContent} data-layout="column">
          <h3 className={styles.footerAmount}>
            Your amount: <strong>${values.amount}</strong>
          </h3>
          <button type="submit" disabled={isSubmitting}>
            Give now
          </button>
          <p className={styles.footerTerms}>
            By clicking on “Give Now” you agree to our Paid Terms of Service.
          </p>
        </div>
      </div>
    </form>
  )
}

const FormikDonate = withFormik<Props, FormProps>({
  mapPropsToValues: ({ defaultAmount }) => ({
    email: '',
    amount: defaultAmount || 25,
    firstName: '',
    lastName: '',
    comment: '',
    billingFirstName: '',
    billingLastName: '',
    address: '',
    captcha: '',
    cardExpiry: false,
    cardCvc: false,
    cardNumber: false
  }),
  validateOnBlur: false,
  validateOnChange: false,
  validationSchema,
  async handleSubmit(values, { props }) {
    if (!props.stripe) {
      return
    }

    const donation = await donate({
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      amount: values.amount * 100,
      comment: values.comment,
      'g-recaptcha-response': values.captcha
    })

    const [response] = donation.flatMatch()

    if (response) {
      const { error } = await props.stripe.handleCardPayment(
        response.paymentId,
        {
          // eslint-disable-next-line
          payment_method_data: {
            // eslint-disable-next-line
            billing_details: {
              name: `${values.billingFirstName} ${values.billingLastName}`,
              email: values.email,
              address: {
                line1: values.address
              }
            }
          }
        }
      )

      if (error) {
        history.push('/donate-failure', { error: error.message })
      } else {
        history.push('/donate-success')
      }
    }
  }
})(DonateForm)

export default injectStripe(FormikDonate)
