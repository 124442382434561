import React from 'react'
import { Link } from 'react-router-dom'

import LandingSection from '../LandingSection'
import Issues from '../shared/issues/Issues'
import { joinClasses } from '../../utils'

const PreferenceSection: React.FC = () => {
  return (
    <LandingSection
      layout="column"
      title={
        <h1 style={{ width: '450px' }}>
          Follow current issues, be part of the solution.
        </h1>
      }
      description={({ className }) => (
        <div className={className} style={{ width: '500px' }}>
          Stay informed on topics you care about and work with your community to
          take action steps.
        </div>
      )}
    >
      <div style={{ paddingTop: '25px' }}>
        <Issues />
      </div>
      <Link to="/get-involved" className={joinClasses('button', 'wide')}>
        Get involved
      </Link>
    </LandingSection>
  )
}

export default PreferenceSection
