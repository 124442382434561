import {
  numberDecoder,
  stringDecoder,
  objectDecoder,
  Decoder
} from '../Decoder'

export interface Country {
  id: number
  name: string
  nationality: string
  code: string
  flag: string
}

export const countryDecoder: Decoder<unknown, Country> = objectDecoder({
  id: numberDecoder,
  name: stringDecoder,
  nationality: stringDecoder,
  code: stringDecoder,
  flag: stringDecoder
})
