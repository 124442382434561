import React from 'react'
import LandingSection from '../LandingSection'

import HomepageImage2 from '../../assets/images/homepage_02.png'

const MovementSection: React.FC = () => {
  return (
    <LandingSection
      layout="row"
      title={
        <h1 style={{ width: '400px' }}>
          Start a movement. Measure your impact.
        </h1>
      }
      description={({ className }) => (
        <div className={className} style={{ maxWidth: '350px' }}>
          Watch your idea spark with other people in the network. Stay connected
          with your supporters and find your community. Raise money for your
          organization, campaign, or impactful business.
        </div>
      )}
      cta={{ to: '/sign-up', label: 'Become a pioneer' }}
    >
      <img
        src={HomepageImage2}
        style={{ maxWidth: '350px' }}
        alt="Women's March profile card preview"
      />
    </LandingSection>
  )
}

export default MovementSection
