import React from 'react'
import { FormikProps, withFormik, Field } from 'formik'
import * as Yup from 'yup'
import qs from 'query-string'
import { connect } from 'react-redux'
import { Redirect } from 'react-router'
import { push } from 'connected-react-router'

import styles from './ResetPassword.module.scss'

import PageHeading from '../components/PageHeading'

import { resetPassword } from '../api/public/Auth'

import { toggleSignInModal } from '../store/UI/actions'

export type Props = {
  location: { search: string }
  toggleSignInModal?: typeof toggleSignInModal
  navigateToHome?: () => void
}

export type FormProps = {
  email: string
  token: string
  password: string
  confirmPassword: string
}

const ResetPassword: React.FC<FormikProps<FormProps> & Props> = ({
  handleSubmit,
  errors,
  status,
  touched,
  isSubmitting,
  values,
  isValid
}) => {
  if (!values.token || !values.email) return <Redirect to="/" />

  return (
    <div className={styles.resetPassword} data-layout="column align-center">
      <PageHeading
        title="Reset password"
        description="Enter new password to the account."
      />
      <form
        onSubmit={handleSubmit}
        data-layout="column u2"
        className={styles.form}
      >
        <Field
          className={errors.password && touched.password && 'invalid'}
          name="password"
          type="password"
          placeholder="Password"
        />
        <Field
          className={
            errors.confirmPassword && touched.confirmPassword && 'invalid'
          }
          name="confirmPassword"
          type="password"
          placeholder="Confirm password"
        />
        <button
          type="submit"
          disabled={isSubmitting || !isValid}
          className={styles.submit}
        >
          Reset password
        </button>
        {status.error && <p className={styles.errorMessage}>{status.error}</p>}
      </form>
    </div>
  )
}

const FormikReset = withFormik<Props, FormProps>({
  mapPropsToValues: ({ location }) => {
    const query = qs.parse(location.search)

    return {
      password: '',
      confirmPassword: '',
      email: query.email ? query.email.toString() : '',
      token: query.token ? query.token.toString() : ''
    }
  },
  validationSchema: Yup.object().shape({
    password: Yup.string()
      .min(3)
      .required(),
    confirmPassword: Yup.string()
      .min(3)
      .required()
      .oneOf([Yup.ref('password')])
  }),
  validateOnBlur: false,
  mapPropsToStatus: () => ({}),
  handleSubmit: ({ email, password, token }, {props, setStatus, setSubmitting} ) => {
    resetPassword({ email, password, token })
      .then(() => {
        props.toggleSignInModal && props.toggleSignInModal()
        props.navigateToHome && props.navigateToHome()
      })
      .catch(err => {
        console.error(err)
        setStatus({ error: err.response.data.message })
        setSubmitting(false)
      })
  }
})(ResetPassword)

const mapDispatchToProps = {
  toggleSignInModal,
  navigateToHome: () => push('/')
}

export default connect(
  null,
  mapDispatchToProps
)(FormikReset)
