import React from 'react'
import styles from './Stage.module.scss'

type Props = {
  name: string
}

const Stage: React.FC<Props> = ({ name, children }) => {
  return (
    <div
      className={styles.stage}
      data-layout-md="row middle"
      data-layout="column u2"
    >
      <h3>{name}</h3>
      <ul>{children}</ul>
    </div>
  )
}

export default Stage
