import React from 'react'
import styles from './LeadershipProfile.module.scss'

type Props = {
  name: string
  avatar: string
  position: string
  additionalInfo: string[]
}

const LeadershipProfile: React.FC<Props> = ({
  name,
  avatar,
  position,
  additionalInfo
}) => {
  return (
    <div className={styles.leadershipProfile} data-layout="row u2">
      <img src={avatar} alt={name}></img>
      <aside data-layout="column u2">
        <div className={styles.name}>{name}</div>
        <div className={styles.position}>{position}</div>
        <ul>
          {additionalInfo.map((info, idx) => (
            <li key={idx}>{info}</li>
          ))}
        </ul>
      </aside>
    </div>
  )
}

export default LeadershipProfile
