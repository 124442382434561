import React from 'react'
import * as Yup from 'yup'
import {
  ReactStripeElements,
  CardNumberElement,
  CardExpiryElement,
  CardCVCElement,
  injectStripe
} from 'react-stripe-elements'
import { FormikProps, withFormik } from 'formik'
import { connect } from 'react-redux'

import styles from './SignUpDonate.module.scss'
import formStyles from '../../components/donation/DonateForm.module.scss'

import FormGroup from '../../components/FormGroup'
import DonationAmountChooser from '../../components/donation/DonationAmountChooser'
import SeatsLeft from '../../components/SeatsLeft'

import { StripeStyle } from '../../constants/stripe'

import { becomePioneer } from '../../api/user/Profile'

import { requestUserFetch } from '../../store/User/actions'
import history from '../../services/History'
import { joinClasses } from '../../utils'

type Props = {
  stripe?: ReactStripeElements.StripeProps
  requestUserFetch?: typeof requestUserFetch
}

type FormProps = {
  address: string
  amount: number
  cardCvc: boolean
  cardExpiry: boolean
  cardNumber: boolean
  fullName: string
}

const SignUpDonateForm: React.FC<FormikProps<FormProps>> = ({
  errors,
  handleBlur,
  handleChange,
  handleSubmit,
  isSubmitting,
  setFieldTouched,
  setFieldValue,
  touched,
  values
}) => {
  const handleStripeInput = (name: keyof FormProps) => ({
    classes: {
      base: formStyles.customInput,
      completed: formStyles.customInput,
      focus: formStyles.active,
      invalid: formStyles.invalid
    },
    className:
      touched[name] && errors[name]
        ? `${formStyles.invalid} ${formStyles.customInput}`
        : formStyles.customInput,
    style: StripeStyle,
    onChange: (change: ReactStripeElements.ElementChangeResponse) => {
      setFieldValue(name, change.complete)
      setFieldTouched(name)
    }
  })

  return (
    <form
      className={joinClasses(formStyles.donateForm, styles.donateForm)}
      onSubmit={handleSubmit}
    >
      <div
        className={joinClasses(formStyles.formSection, styles.formSection)}
        data-layout="column u2 align-center"
      >
        <h2 className={formStyles.formSectionTitleSimple}>Select an amount</h2>
        <DonationAmountChooser
          name="amount"
          small
          handleChange={setFieldValue}
          amount={values.amount}
        />
        <div data-layout="column u2" className={styles.paymentDetails}>
          <FormGroup>
            <CardNumberElement {...handleStripeInput('cardNumber')} />
          </FormGroup>
          <FormGroup>
            <CardExpiryElement {...handleStripeInput('cardExpiry')} />
            <CardCVCElement {...handleStripeInput('cardCvc')} />
          </FormGroup>
          <FormGroup>
            <input
              name="fullName"
              value={values.fullName}
              onBlur={handleBlur}
              onChange={handleChange}
              placeholder="Full Name"
              className={touched.fullName && errors.fullName ? 'invalid' : ''}
            />
          </FormGroup>
          <FormGroup>
            <input
              name="address"
              value={values.address}
              onBlur={handleBlur}
              onChange={handleChange}
              placeholder="Billing address"
              className={touched.address && errors.address ? 'invalid' : ''}
            />
          </FormGroup>
        </div>
      </div>
      <div
        className={joinClasses(
          formStyles.formSection,
          formStyles.paySection,
          styles.formSection
        )}
        data-layout="column align-center"
      >
        <div
          className={formStyles.paySectionContent}
          data-layout="column align-center"
        >
          <h3 className={formStyles.footerAmount}>
            Your amount: <strong>${values.amount}</strong>
          </h3>
          <button
            type="submit"
            disabled={isSubmitting}
            style={{ width: '270px' }}
          >
            Donate now
          </button>
          <p className={formStyles.footerTerms}>
            By clicking on "Donate Now” you agree to our Paid Terms of Service.
          </p>
          <SeatsLeft className={styles.seatsLeft} inline text="Seats left" />
        </div>
      </div>
    </form>
  )
}

const FormikDonate = withFormik<Props, FormProps>({
  mapPropsToValues: () => ({
    address: '',
    amount: 25,
    cardCvc: false,
    cardExpiry: false,
    cardNumber: false,
    fullName: ''
  }),
  validateOnBlur: false,
  validateOnChange: false,
  validationSchema: Yup.object().shape({
    address: Yup.string().required(),
    amount: Yup.number().min(1),
    cardCvc: Yup.boolean().oneOf([true]),
    cardExpiry: Yup.boolean().oneOf([true]),
    cardNumber: Yup.boolean().oneOf([true]),
    fullName: Yup.string().required()
  }),
  async handleSubmit(values, { props }) {
    if (!props.stripe) {
      return
    }

    const payment = await becomePioneer(values.amount * 100)
    const [response] = payment.flatMatch()

    if (response) {
      const { error } = await props.stripe.handleCardPayment(
        response.paymentId,
        {
          // eslint-disable-next-line
          payment_method_data: {
            // eslint-disable-next-line
            billing_details: {
              name: values.fullName,
              address: {
                line1: values.address
              }
            }
          }
        }
      )

      if (error) {
        history.push('/donate-failure', {
          error: error.message,
          returnUrl: '/sign-up/donate'
        })
      } else {
        props.requestUserFetch && props.requestUserFetch()
        history.push('/dashboard')
      }
    }
  }
})(SignUpDonateForm)

const mapDispatchToProps = {
  requestUserFetch
}

const ConnectFormikDonate = connect(
  null,
  mapDispatchToProps
)(FormikDonate)

export default injectStripe(ConnectFormikDonate)
