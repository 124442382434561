import { stringDecoder, objectDecoder, Decoder } from '../Decoder'
import { User, userDecoder } from './User'

export interface AuthLogin {
  token: string
}

export interface AuthRegister {
  user: User
  token: string
}

export const authLoginDecoder: Decoder<unknown, AuthLogin> = objectDecoder({
  token: stringDecoder
})

export const authRegisterDecoder: Decoder<
  unknown,
  AuthRegister
> = objectDecoder({
  user: userDecoder,
  token: stringDecoder
})
