import React from 'react'
import { Link } from 'react-router-dom'
import { RouteComponentProps } from 'react-router'

import PageHeading from '../components/PageHeading'

import styles from './DonateFailure.module.scss'

import { joinClasses } from '../utils'

export type StateProps = {
  error: string
  returnUrl?: string
}

const DonateFailure: React.FC<RouteComponentProps<{}, {}, StateProps>> = ({
  location
}) => {
  const { error, returnUrl = '/donate' } = location.state

  return (
    <div
      className={styles.donateSuccess}
      data-layout="column center align-center"
    >
      <PageHeading
        prepend="Donate"
        title="We've encountered an error with your payment"
        description={error}
      />
      <Link to={returnUrl} className={joinClasses('button', styles.button)}>
        Donate again
      </Link>
    </div>
  )
}

export default DonateFailure
