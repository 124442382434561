import { userApi } from '../apiConfig'
import { decodeServerResponse } from '../utils'
import { userDecoder } from '../../types/models/User'
import { becomePioneerDecoder } from '../../types/models/Donator'

export async function fetchUser() {
  const response = await userApi.get('/me')
  return decodeServerResponse(response).andThen(v => {
    return userDecoder.decode(v.data)
  })
}

export function savePreferences(preferences: number[]) {
  return userApi.post('/me/preferences', {
    preferences: preferences.map(id => ({ id }))
  })
}

export function saveTopics(topics: number[]) {
  return userApi.post('/me/topics', {
    topics: topics.map(id => ({ id }))
  })
}

export function saveCountriesNationalities(
  countries: number[],
  nationalities: number[]
) {
  return userApi.post('/me/countries-nationalities', {
    countries: countries.map(id => ({ id })),
    nationalities: nationalities.map(id => ({ id }))
  })
}

export async function becomePioneer(amount: number) {
  const response = await userApi.post('/me/become-pioneer', { amount })
  return decodeServerResponse(response).andThen(v => {
    return becomePioneerDecoder.decode(v.data)
  })
}

export async function logout() {
  return userApi.get('/logout')
}
