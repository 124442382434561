const _none = Symbol('nothing')

/**
 * This type represents a value that might not
 * be present.
 */
export class Option<T> {
  private _value: T | typeof _none

  private constructor(v: T | typeof _none) {
    this._value = v
  }

  static from<T>(v: T | null | undefined) {
    if (v === null || v === undefined) {
      return Option.None<T>()
    } else {
      return Option.Some<T>(v)
    }
  }

  /**
   * Constructor that creates Option representing some value `v`.
   */
  static Some<T>(v: T) {
    return new Option(v)
  }

  /**
   * Constructor that creates Option representing lack of value.
   */
  static None<T>() {
    return new Option<T>(_none)
  }

  isSome() {
    return this._value !== _none
  }

  isNothing() {
    return this._value === _none
  }

  /**
   * `Maps` over a value, if the value was `Option.None`, nothing happens.
   * @param fn
   */
  map<_T>(fn: (v: T) => _T): Option<_T> {
    if (this._value !== _none) {
      return Option.Some(fn(this._value))
    } else {
      return Option.None()
    }
  }

  /**
   * Based on a predicate, discards `Option.Some` value.
   */
  filter(predicate: (v: T) => boolean): Option<T> {
    if (this._value !== _none) {
      if (predicate(this._value)) {
        return Option.Some(this._value)
      } else {
        return Option.None()
      }
    } else {
      return Option.None()
    }
  }

  /**
   * Applies a function `fn` over a value.
   * @param fn
   */
  andThen<_T>(fn: (v: T) => Option<_T>): Option<_T> {
    if (this._value !== _none) {
      return fn(this._value)
    } else {
      return Option.None()
    }
  }

  /**
   * Matches conditionally the value inside, and then applies corresponding
   * function.
   * @param matchCase An object with a function for each case (`Option.Some` and `Option.None`)
   */
  match<O>(matchCase: { some: (v: T) => O; none: () => O }): O {
    if (this._value === _none) {
      return matchCase.none()
    } else {
      return matchCase.some(this._value)
    }
  }

  /**
   * Returns wrapped value.
   *
   * __WARNING__: This is potentially __dangerous__ and should be used only with prior
   * check if Option is `Option.Some`.
   */
  unwrap(): T {
    if (this._value === _none) {
      throw new Error('Tried to unwrap Option.None!')
    } else {
      return this._value
    }
  }

  /**
   * Returns wrapped value, or provided fallback in case of value being `Option.None`.
   * @param value
   */
  unwrapOr(value: T): T {
    if (this._value === _none) {
      return value
    } else {
      return this._value
    }
  }

  onSome(fn: (v: T) => void) {
    if (this._value !== _none) {
      fn(this._value)
    }
  }

  onNone(fn: () => void) {
    if (this._value === _none) {
      fn()
    }
  }
}
