import React from 'react'
import { Route, Redirect, RouteProps } from 'react-router'
import { useSelector } from 'react-redux'
import { getUser } from '../../store/User/selectors'

const AuthRoute: React.FC<RouteProps> = props => {
  const user = useSelector(getUser)

  if (!user) {
    return <Redirect to="/" />
  }

  return <Route {...props} />
}

export default AuthRoute
