import React from 'react'
import styles from './Spinner.module.scss'
import { joinClasses } from '../../utils'

type Props = {
  className?: string
}

const Spinner: React.FC<Props> = ({ className }) => {
  return <div className={joinClasses(styles.spinner, className)}></div>
}

export default Spinner
