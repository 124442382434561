import React from 'react'
import styles from './ProfileAvatar.module.scss'
import { User, UserType } from '../types/models/User'
import { joinClasses } from '../utils'
import { StarIcon } from '../assets/icons'

type Props = {
  user: User
  size?: 'large' | 'medium' | 'small'
  onClick?: () => void
}

const ProfileAvatar: React.FC<Props> = ({ user, onClick, size = 'small' }) => {
  const username = user.fullName
  const initials = username
    .split(' ')
    .map(name => name.substring(0, 1).toUpperCase())
    .join('')

  return (
    <div
      className={joinClasses(
        styles.profileAvatar,
        styles[size],
        onClick && styles.clickable
      )}
      data-layout="row middle align-center"
      onClick={onClick}
    >
      <div className={styles.initials}>{initials}</div>
      {user.type === UserType.pioneer && (
        <div className={styles.star} data-layout="row middle align-center">
          <StarIcon className={styles.starIcon} />
        </div>
      )}
    </div>
  )
}

export default ProfileAvatar
