import React, { Fragment } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import SignUpForm from './SignUpForm'
import TagLine from './shared/TagLine'

import styles from './HeroSignUp.module.scss'

import { getUser } from '../store/User/selectors'
import { joinClasses } from '../utils'

const HeroSignUp: React.FC = () => {
  const user = useSelector(getUser)

  return (
    <div
      className={styles.heroSignUp}
      data-layout="column middle align-center u4"
      data-flex="1"
    >
      <div data-layout="column align-center">
        <h1>Take a seat at the table</h1>
        <div className={styles.tagLine}>
          A social platform with a purpose, and you're invited.
        </div>
      </div>
      {user ? (
        <Fragment>
          <Link
            to="donate"
            className={joinClasses('button', styles.donateButton)}
          >
            Donate
          </Link>
          <p className={styles.donateReason}>
            Be a part of the HumanAct movement and help us reach our goals by
            making a small donation.
          </p>
        </Fragment>
      ) : (
        <SignUpForm />
      )}
      <TagLine className={styles.afterword} />
    </div>
  )
}

export default HeroSignUp
