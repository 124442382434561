type ResultValue<V, E> = { type: 'ok'; val: V } | { type: 'error'; error: E }

export class Result<V, E> {
  private _val: ResultValue<V, E>

  private constructor(val: ResultValue<V, E>) {
    this._val = val
  }

  static Ok<V, E = any>(val: V): Result<V, E> {
    return new Result({ type: 'ok', val })
  }

  static Error<V, E = any>(error: E): Result<V, E> {
    return new Result({ type: 'error', error })
  }

  isOk(): boolean {
    return this._val.type === 'ok'
  }

  isError(): boolean {
    return this._val.type === 'error'
  }

  unwrap(): V {
    if (this._val.type === 'ok') {
      return this._val.val
    } else {
      throw new Error('Tried to unwrap Result, but value was Error')
    }
  }

  map<_V>(fn: (v: V) => _V): Result<_V, E> {
    if (this._val.type === 'ok') {
      return Result.Ok(fn(this._val.val))
    } else {
      return Result.Error(this._val.error)
    }
  }

  andThen<R extends Result<any, any>>(fn: (v: V) => R): R {
    if (this._val.type === 'ok') {
      return fn(this._val.val)
    } else {
      return Result.Error(this._val.error) as R
    }
  }

  match<T>(matchCase: { ok: (v: V) => T; error: (e: E) => T }): T {
    if (this._val.type === 'ok') {
      return matchCase.ok(this._val.val)
    } else {
      return matchCase.error(this._val.error)
    }
  }

  flatMatch<T>(): [V?, E?] {
    if (this._val.type === 'ok') {
      return [this._val.val]
    } else {
      return [undefined, this._val.error]
    }
  }

  onOk(fn: (v: V) => void) {
    if (this._val.type === 'ok') {
      fn(this._val.val)
    }
  }

  onError(fn: (error: E) => void) {
    if (this._val.type === 'error') {
      fn(this._val.error)
    }
  }

  asPromise(): Promise<V> {
    if (this._val.type === 'ok') {
      return Promise.resolve(this._val.val)
    } else {
      return Promise.reject(this._val.error)
    }
  }
}
