import React from 'react'
import { Elements } from 'react-stripe-elements'

import PageHeading from '../components/PageHeading'
import DonateForm from '../components/donation/DonateForm'
import Accordion from '../components/Accordion'
import AccordionItem from '../components/AccordionItem'

import styles from './Donate.module.scss'

import { DONATE_FAQ } from '../constants/faq'

export type DonateRouteState = { amount: number }

type RouterProps = {
  location: { state?: DonateRouteState }
}

const Donate: React.FC<RouterProps> = ({ location }) => {
  return (
    <div className={styles.donate} data-layout="column center align-center">
      <PageHeading
        prepend="Donate"
        title="Support HumanAct"
        description="
        We are building a platform where we all can shape and affect global
        progress. Make a small donation to support our movement and become a
        pioneer supporter."
        
      />
      
      <Elements
        fonts={[
          {
            cssSrc: 'https://fonts.googleapis.com/css?family=Open+Sans:400'
          }
        ]}
      >
        <DonateForm defaultAmount={location.state && location.state.amount} />
      </Elements>
      <div style={{ maxWidth: '600px', overflow: 'hidden', marginTop: '10px', marginBottom: '50px', textAlign: 'center' }}>
        <p style={{ fontFamily: 'Open Sans', fontWeight: 'lighter' , fontSize: '12px'}}><strong>Secure donation.</strong> Your gift will help HumanAct build a platform that empowers people to choose a sustainable future. Our mission is to open a dialog between leaders of the world and the people. Donations are not tax-deductible.</p>
      </div>
      <section className={styles.faqSection} data-layout="column align-center">
        <h1 className={styles.sectionHeading}>Frequently Asked Questions</h1>

        <Accordion className={styles.faq}>
          {DONATE_FAQ.map((data, index) => (
            <AccordionItem title={data.title} key={index}>
              {data.content}
            </AccordionItem>
          ))}
        </Accordion>
      </section>
    </div>
  )
}

export default Donate
