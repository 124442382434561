import { api } from '../apiConfig'
import { decodeServerResponse } from '../utils'
import { arrayDecoder } from '../../types/Decoder'
import { countryDecoder } from '../../types/models/Country'

export async function getCountryList() {
  const response = await api.get('/countries')
  const countryListDecoder = arrayDecoder(countryDecoder)
  return decodeServerResponse(response).andThen(v => {
    return countryListDecoder.decode(v.data)
  })
}
