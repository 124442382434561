import createSagaMiddleware from 'redux-saga'
import { createStore, applyMiddleware } from 'redux'
import { routerMiddleware } from 'connected-react-router'
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly'
import History from '../services/History'

import rootSaga from './sagas'
import rootReducer from './reducers'

const sagaMiddleware = createSagaMiddleware()

const middlewares = applyMiddleware(sagaMiddleware, routerMiddleware(History))

const store = createStore(
  rootReducer(History),
  composeWithDevTools(middlewares)
)

sagaMiddleware.run(rootSaga)

export default store
