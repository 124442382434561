import React, { Fragment } from 'react'

import styles from './CookiePolicy.module.scss'

import PageHeading from '../components/PageHeading'

import { CONTENT } from './CookiePolicy.content'

const CookiePolicy: React.FC = () => {
  return (
    <div className={styles.cookiePolicy}>
      <div className={styles.header} data-layout="column center align-center">
        <PageHeading
          prepend="Effective: Nov. 10th 2019"
          title={
            <Fragment>
              Cookie Policy
            </Fragment>
          }
        />
      </div>
      <div className={styles.content} data-layout="column align-center">
        {CONTENT.map(({ title, subtitle, content }) => (
          <section className={styles.section}>
            <h1 className={styles.sectionTitle}>{title}</h1>
            {subtitle && <h2 className={styles.sectionSubtitle}>{subtitle}</h2>}
            {content.split('\n').map(line => (
              <p className={styles.sectionParagraph}>{line}</p>
            ))}
          </section>
        ))}
      </div>
      <div className={styles.spacer}></div>
    </div>
  )
}

export default CookiePolicy
