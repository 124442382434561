import { api } from '../apiConfig'
import { decodeServerResponse } from '../utils'
import { seatsLeftDecoder } from '../../types/models/Stats'

export async function seatsLeft() {
  const response = await api.get('/stats/seats')
  return decodeServerResponse(response).andThen(v => {
    return seatsLeftDecoder.decode(v.data)
  })
}
