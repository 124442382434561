import React, { useState, useEffect } from 'react'
import * as Yup from 'yup'
import { Link } from 'react-router-dom'

import styles from './SignUpPreferences.module.scss'
import shared from './SignUpShared.module.scss'
import {
  FieldArray,
  FormikProps,
  withFormik,
  FieldArrayRenderProps
} from 'formik'

import PageHeading from '../../components/PageHeading'

import { savePreferences } from '../../api/user/Profile'
import { getPreferenceList } from '../../api/public/Preference'

import { Preference } from '../../types/models/Preference'

import history from '../../services/History'

import { Checkmark } from '../../assets/icons'

type FormProps = {
  preferences: number[]
}

const SignUpPreferences: React.FC<FormikProps<FormProps>> = ({
  values,
  isSubmitting,
  isValid,
  handleSubmit
}) => {
  const [preferences, setPreferences] = useState<Preference[]>([])

  useEffect(() => {
    getPreferenceList().then(pref => pref.onOk(setPreferences))
  }, [])

  const renderPreference = (
    preference: Preference,
    arrayHelpers: FieldArrayRenderProps
  ) => (
    <label
      key={preference.id}
      className={styles.preference}
      data-layout="row u2"
    >
      <input
        type="checkbox"
        className={styles.preferenceCheckbox}
        onChange={e => {
          if (e.target.checked) arrayHelpers.push(preference.id)
          else {
            const idx = values.preferences.indexOf(preference.id)
            arrayHelpers.remove(idx)
          }
        }}
      />
      <span className={styles.preferenceCheckmark}>
        {values.preferences.includes(preference.id) && <Checkmark />}
      </span>
      <span className={styles.preferenceTitle}>
        {preference.name}
        <small className={styles.preferenceSubTitle}>
          {preference.description}
        </small>
      </span>
    </label>
  )

  return (
    <div className={styles.signUpPreferences} data-layout="column align-center">
      <PageHeading
        title="What brings you here?"
        description="This will customize your experience."
      />
      <form data-layout="column align-center" onSubmit={handleSubmit}>
        <FieldArray
          name="preferences"
          render={arrayHelpers => (
            <div data-layout="column u2" data-layout-md="row u2">
              <div className={styles.preferenceGroup} data-layout="column u2">
                <h2>Individuals</h2>
                {preferences
                  .slice(0, 4)
                  .map(preference =>
                    renderPreference(preference, arrayHelpers)
                  )}
              </div>
              <div className={styles.preferenceGroup} data-layout="column u2">
                <h2>Organizations and Companies</h2>
                {preferences
                  .slice(4)
                  .map(preference =>
                    renderPreference(preference, arrayHelpers)
                  )}
              </div>
            </div>
          )}
        />
        <button
          disabled={isSubmitting || !isValid}
          className={styles.submit}
          style={{ width: '250px' }}
          type="submit"
        >
          Save and continue
        </button>
        <Link to="/sign-up/topics" className={styles.skip}>
          Skip for now
        </Link>
      </form>
      <div className={shared.step}>Step 1 of 4</div>
    </div>
  )
}

export default withFormik<{}, FormProps>({
  validationSchema: Yup.object().shape({
    preferences: Yup.array()
      .of(Yup.number())
      .min(1)
  }),
  mapPropsToValues: () => ({
    preferences: []
  }),
  async handleSubmit({ preferences }) {
    try {
      await savePreferences(preferences)
      history.push('/sign-up/topics')
    } catch (error) {
      console.error(error)
    }
  }
})(SignUpPreferences)
