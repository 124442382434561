import React, { useEffect, useState } from 'react'
import styles from './CelebrationTag.module.scss'
import { useTransition, animated } from 'react-spring'
import { getCountryList } from '../api/public/Country'
import { Country } from '../types/models/Country'
import { selectRandomFromList } from '../utils'

type Props = {
  /** How long every contry will be displayed */
  duration: number
}

const CelebrationTag: React.FC<Props> = ({ duration }) => {
  const [countryList, setCountryList] = useState<Country[]>([])
  const [currentCountry, setCountry] = useState<Country | null>(null)

  useEffect(() => {
    getCountryList().then(res =>
      res.onOk(list => {
        setCountryList(list)
        setCountry(selectRandomFromList(list))
      })
    )
  }, [])

  const transitions = useTransition(currentCountry, c => c && c.name, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: {
      tension: 400
    }
  })

  useEffect(() => {
    const handle = setInterval(() => {
      const randomCountry = selectRandomFromList(countryList)
      setCountry(randomCountry)
    }, duration)
    return function cleanup() {
      clearInterval(handle)
    }
  })

  return (
    <div className={styles.celebrationTag}>
      We celebrate people of
      {transitions.map(
        ({ item, key, props }) =>
          item && (
            <animated.div key={key} style={props} className={styles.country}>
              {item.name}
            </animated.div>
          )
      )}
    </div>
  )
}

export default CelebrationTag
