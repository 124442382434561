import React from 'react'
import LandingSection from '../LandingSection'
import SmallSupportWidget from '../SmallSupportWidget'
import JoinUsWidget from '../JoinUsWidget'

const DonationSection: React.FC = () => {
  return (
    <LandingSection layout="row">
      <div
        data-layout-md="column u5 align-center"
        data-layout-lg="row u0 around"
        data-flex="1"
      >
        <SmallSupportWidget />
        <JoinUsWidget />
      </div>
    </LandingSection>
  )
}

export default DonationSection
