import React from 'react'
import { Link } from 'react-router-dom'

//import HomepageImage4 from '../../assets/images/homepage_04.gif'
import LandingSection from '../LandingSection'
import { joinClasses, useScrollState } from '../../utils'
import styles from '../../pages/GetInvolved.module.scss'

const WeAllSection: React.FC = () => {
  return (
    <LandingSection
      layout="column"
      bottomPadding={false}
      title={
        <h1 style={{ maxWidth: '600px' }}>
          Be part of the solution.
        </h1>
      }
      description={({ className }) => (
        <div className={className} style={{ width: '500px' }}>
          We are just getting started. Help us build a platform for global societal transformation. Become one of our early supporters.
        </div>
      )}
      
      cta={{ to: '/donate', label: 'Donate Now' }}
      
    >
      <div style={{ maxWidth: '800px', overflow: 'hidden', marginTop: '45px', marginBottom: '100px', textAlign: 'center' }}>
        
      </div>
      
      
    </LandingSection>
  )
}

export default WeAllSection
