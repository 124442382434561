import React from 'react'
import LandingSection from '../LandingSection'

import HomepageImage1 from '../../assets/images/homepage_01.png'

const ProfileSection: React.FC = () => {
  return (
    
    <LandingSection
      layout="row-reverse"
      title={<h1>Find your inner activist.</h1>}
      description={({ className }) => (
        <div className={className} style={{ width: '350px' }}>
          Build your profile. Connect to a network of activists and change
          makers around the world. Follow current issues and get involved with
          movements.
        </div>
      )}
      cta={{ to: '/get-involved', label: 'Get involved' }}
    >
      <img
        src={HomepageImage1}
        style={{ maxWidth: '350px' }}
        alt="Your profile card preview"
      />
    </LandingSection>
  )
}

export default ProfileSection
