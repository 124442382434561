import React, { Fragment, useEffect } from 'react'
import { withFormik, FormikProps, Field } from 'formik'
import { connect } from 'react-redux'
import * as Yup from 'yup'

import history from '../services/History'

import styles from './SignUpForm.module.scss'

import { toggleSignInModal } from '../store/UI/actions'
import { requestUserRegister } from '../store/User/actions'
import { Link } from 'react-router-dom'
import { ApplicationState } from '../store/reducers'
import { getError } from '../store/User/selectors'
import { StatusType } from '../constants/statusTypes'

type Props = {
  displaySignIn?: boolean
  displayTerms?: boolean
  email?: string
  formStatus?: StatusType
  full?: boolean
  fullName?: string
  registerError?: any
  requestUserRegister?: typeof requestUserRegister
  toggleSignInModal?: typeof toggleSignInModal
}

type FormProps = {
  email: string
  password: string
  fullName: string
}

const SignUpForm: React.FC<Props & FormikProps<FormProps>> = ({
  displaySignIn = true,
  displayTerms = true,
  errors,
  formStatus,
  full = false,
  handleSubmit,
  isSubmitting,
  isValid,
  registerError,
  setSubmitting,
  toggleSignInModal,
  touched
}) => {
  const isRequired = (value: string) => value.length < 3
  const resetFormState = () => {
    if (formStatus === StatusType.FAILED) {
      setSubmitting(false)
    }
  }

  useEffect(resetFormState, [formStatus])

  return (
    <Fragment>
      <form
        data-layout="column u1"
        onSubmit={handleSubmit}
        className={styles.form}
      >
        <Field
          className={errors.email && touched.email && 'invalid'}
          name="email"
          type="email"
          placeholder="Enter your email"
        />
        <Field
          className={errors.fullName && touched.fullName && 'invalid'}
          name="fullName"
          type="text"
          placeholder="Full name"
        />
        {full && (
          <Field
            className={errors.password && touched.password && 'invalid'}
            name="password"
            type="password"
            placeholder="Password"
            validate={isRequired}
          />
        )}
        <button
          type="submit"
          disabled={full ? !isValid || isSubmitting : false}
        >
          {full ? 'Continue' : 'Sign up'}
        </button>
        {registerError && (
          <p className={styles.errorMessage}>{registerError}</p>
        )}
      </form>
      {displayTerms && (
        <div className={styles.terms}>
          By signing up, you agree to our
          <br />
          <Link to="/terms-of-service">Terms &amp; Privacy Policy</Link>.
        </div>
      )}
      {displaySignIn && (
        <div className={styles.signIn}>
          Have an account?
          <span onClick={toggleSignInModal}>Sign in</span>
        </div>
      )}
    </Fragment>
  )
}

const FormikSignUpForm = withFormik<Props, FormProps>({
  mapPropsToValues: props => ({
    email: props.email || '',
    fullName: props.fullName || '',
    password: ''
  }),
  validateOnBlur: false,
  validateOnChange: false,
  validationSchema: Yup.object().shape({
    email: Yup.string()
      .email()
      .required(),
    fullName: Yup.string()
      .min(3)
      .required()
  }),
  async handleSubmit({ email, password, fullName }, { props }) {
    if (props.full && props.requestUserRegister) {
      props.requestUserRegister(email, password, fullName)
    } else {
      history.push('/sign-up', { email, fullName })
    }
  }
})(SignUpForm)

const mapDispatchToProps = {
  toggleSignInModal,
  requestUserRegister
}

const mapStateToProps = (state: ApplicationState) => ({
  registerError: getError(state),
  formStatus: state.user.status.name
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FormikSignUpForm)
