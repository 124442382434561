import {
  stringDecoder,
  objectDecoder,
  Decoder,
  numberDecoder,
  optional
} from '../Decoder'

export interface DonationResponse {
  paymentId: string
  donator: Donator
}

export interface Donator {
  id: number
  uuid: string
  email: string
  firstName: string
  lastName: string
  comment: string
  createdAt: Date
  updatedAt: Date
  deletedAt?: Date
}

export const donateDecoder: Decoder<unknown, DonationResponse> = objectDecoder({
  paymentId: stringDecoder,
  donator: objectDecoder({
    id: numberDecoder,
    uuid: stringDecoder,
    email: stringDecoder,
    firstName: stringDecoder,
    lastName: stringDecoder,
    comment: stringDecoder,
    createdAt: stringDecoder,
    updatedAt: stringDecoder,
    deletedAt: optional(stringDecoder)
  })
})

export interface BecomePioneerResponse {
  paymentId: string
}

export const becomePioneerDecoder: Decoder<
  unknown,
  BecomePioneerResponse
> = objectDecoder({
  paymentId: stringDecoder
})
