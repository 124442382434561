import React from 'react'
import styles from './BottomNavSection.module.scss'

export type BottomNavProps = {
  name: string
}

const BottomNavSection: React.FC<BottomNavProps> = ({ name, children }) => {
  return (
    <div className={styles.bottomNavSection} data-layout="column u2">
      <div className={styles.name}>{name}</div>
      <div className={styles.links} data-layout="column u1">
        {children}
      </div>
    </div>
  )
}

export default BottomNavSection
