import React, { useEffect, useState, useRef }  from 'react'
import styles from './Landing.module.scss'

import HeroSignUp from '../components/HeroSignUp'
import HeroImage from '../components/HeroImage'

import Stats from '../components/shared/Stats'
import { seatsLeft } from '../api/public/Stats'
import { SeatsLeftModel } from '../types/models/Stats'

import CookieConsent from "react-cookie-consent"
import { Link } from 'react-router-dom'



import {
  ConversationSection,
  DonationSection,
  FaqSection,
  ScheduleSection,
  ProfileSection,
  PreferenceSection,
  PolicyMakerSection,
  StatsSection,
  MovementSection,
  WeAllSection
} from '../components/landingSections'

const Landing: React.FC = () => {

  return (
    <div className={styles.landing}>
      <div
        className={styles.heroSection}
        data-layout="column"
        data-layout-md="row-reverse"
      >
        <HeroImage />
        <HeroSignUp />
      </div>
      
      <StatsSection />
      <WeAllSection />
      <ProfileSection />
      <MovementSection />
      <ConversationSection />
      <PolicyMakerSection />
      <PreferenceSection />
      <ScheduleSection />
      <FaqSection />
      <DonationSection />
      <CookieConsent
        //enableDeclineButton
        //flipButtons
        location="bottom"
        buttonText="OKAY"
        cookieName="myAwesomeCookieName2"
        style={{ background: "#fff", boxShadow: "0px 0px 15px #ededed", textAlign: "center"}}
        buttonStyle={{ background: "#25c5ae", fontSize: "13px", color: "#fff", borderRadius: "50vh" }}
        contentStyle={{ color: "#222" }}
        expires={150}
    >
        We use cookies to improve your experience on our site.{" "}
        <span style={{ fontSize: "13px", color: "#666", textDecoration: "underline" }}>
          <Link to="/cookie-policy">Find out more</Link>
        </span>
    </CookieConsent>
    </div>
  )
}

export default Landing
