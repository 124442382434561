import { StatusType } from '../../constants/statusTypes'
import { Reducer } from '../types'
import { UserActions } from './actionTypes'

export interface UserState {
  data: any
  error: string
  status: {
    name: StatusType
    isLoading: boolean
  }
}

const initialState: UserState = {
  data: {},
  error: '',
  status: {
    name: StatusType.INITIAL,
    isLoading: false
  }
}

const UserReducer: Reducer<UserState, UserActions> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case 'USER_ACTIVATE_STARTED':
    case 'USER_FETCH_STARTED':
    case 'USER_LOGOUT_STARTED':
    case 'USER_REGISTER_STARTED':
    case 'USER_LOGIN_STARTED': {
      const newStatus = {
        name: StatusType.STARTED,
        isLoading: true
      }

      return {
        ...state,
        status: newStatus
      }
    }

    case 'USER_FETCH_SUCCEEDED':
    case 'USER_LOGIN_SUCCEEDED': {
      const newStatus = {
        name: StatusType.SUCCEEDED,
        isLoading: false
      }

      return {
        data: action.payload,
        error: '',
        status: newStatus
      }
    }

    case 'USER_ACTIVATE_SUCCEEDED':
    case 'USER_REGISTER_SUCCEEDED': {
      const newStatus = {
        name: StatusType.SUCCEEDED,
        isLoading: false
      }

      return {
        ...state,
        error: '',
        status: newStatus
      }
    }

    case 'USER_LOGOUT_SUCCEEDED': {
      return {
        ...state,
        data: {}
      }
    }

    case 'USER_FETCH_FAILED':
    case 'USER_ACTIVATE_FAILED':
    case 'USER_LOGOUT_FAILED':
    case 'USER_REGISTER_FAILED':
    case 'USER_LOGIN_FAILED': {
      const newStatus = {
        name: StatusType.FAILED,
        isLoading: false
      }

      return {
        ...state,
        error: action.payload.error,
        status: newStatus
      }
    }

    case 'USER_ERROR_RESET':
      return {
        ...state,
        error: ''
      }

    default:
      return state
  }
}

export default UserReducer
