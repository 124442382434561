import React, { useEffect, useState, useRef }  from 'react'
import LandingSection from '../LandingSection'
import styles from '../../pages/Landing.module.scss'


import Stats from '../../components/shared/Stats'
import { seatsLeft } from '../../api/public/Stats'
import { SeatsLeftModel } from '../../types/models/Stats'

const StatsSection: React.FC = () => {
  const [stats, setSeats] = useState<SeatsLeftModel>({
    actionsTakenToSupport: 0,
    preLaunchUsers: 0,
    seatsLeft: 0
  })

  useEffect(() => {
    seatsLeft().then(seats => seats.onOk(setSeats))
  }, [])

  return (
    <LandingSection 
      layout="column"
      title={<h1>Join the movement.</h1>}
      description={({ className }) => (
        <div className={className} style={{ width: '350px' }}>
          Become a Pioneer Member.
        </div>
      )}
    >
      <div className={styles.statsLine} data-layout="column align-center">
        <Stats
          stats={[
            {
              count: stats.preLaunchUsers,
              description: 'Signed up\nto date'
            },
            {
              count: stats.actionsTakenToSupport,
              description: 'Early Pioneer \nSupporters'
            },
            {
              count: stats.seatsLeft,
              description: 'Pioneer seats \navailable'
            }
          ]}
        />
      </div>
    </LandingSection>
  )
}

export default StatsSection
