import React from 'react'
import LandingSection from '../LandingSection'

import HomepageImage3 from '../../assets/images/homepage_03.png'

const ConversationSection: React.FC = () => {
  return (
    <LandingSection
      layout="row-reverse"
      title={<h1>Make your voice heard.</h1>}
      description={({ className }) => (
        <div className={className} style={{ width: '350px' }}>
          Join the conversation and take a stand on current local and global
          issues. Impact the actions of policy makers and demand a more
          sustainable future.
        </div>
      )}
      cta={{ to: '/get-involved', label: 'Get involved' }}
    >
      <img
        src={HomepageImage3}
        style={{ maxWidth: '500px' }}
        alt="Example discussion"
      />
    </LandingSection>
  )
}

export default ConversationSection
