import React from 'react'
import styles from './DropdownButton.module.scss'
import { Location } from 'history'
import { Link } from 'react-router-dom'

type Props = {
  onClick?: () => void
  linkTo?: Location<any> | string
}

const DropdownButton: React.FC<Props> = ({ children, onClick, linkTo }) => {
  const button = (
    <div className={styles.dropdownButton} onClick={onClick}>
      {children}
    </div>
  )
  return linkTo ? <Link to={linkTo}>{button}</Link> : button
}

export default DropdownButton
