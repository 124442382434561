import React, { useState } from 'react'
import styles from './SmallSupportWidget.module.scss'
import { Option } from '../types/Option'
import { joinClasses } from '../utils'
import { DonateRouteState } from '../pages/Donate'
import History from '../services/History'

const buttons = [
  { label: '$5', value: 5 },
  { label: '$25', value: 25 },
  { label: '$50', value: 50 },
  { label: '$75', value: 75 },
  { label: '$100', value: 100 },
  { label: 'Other', value: 15 }
]

const SmallSupportWidget: React.FC = () => {
  const [selectedAmount, setAmount] = useState<Option<number>>(Option.None())

  const navigateToDonate = (state: DonateRouteState) =>
    History.push('/donate', state)

  return (
    <div
      className={styles.smallSupportWidget}
      data-layout="column u5 middle align-center"
      data-layout-md="column u0 start"
    >
      <h1>Support HumanAct</h1>
      <div className={styles.buttons} data-layout="row u1">
        {buttons.map(({ label, value }) => (
          <button
            key={label}
            className={joinClasses(
              'ghost-gray',
              selectedAmount.map(v => v === value).unwrapOr(false) &&
                styles.selected
            )}
            onClick={() => setAmount(Option.Some(value))}
          >
            {label}
          </button>
        ))}
        <button
          className={styles.donate}
          disabled={selectedAmount.isNothing()}
          onClick={() =>
            selectedAmount.onSome(amount => navigateToDonate({ amount }))
          }
        >
          Donate
        </button>
      </div>
      <button
        className={styles.donateMobile}
        onClick={() => navigateToDonate({ amount: 15 })}
      >
        Donate
      </button>
    </div>
  )
}

export default SmallSupportWidget
