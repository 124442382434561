import { Result } from './Result'

export interface DecodeError<I> {
  msg: string
  input: I
}

export interface Decoder<I, O> {
  readonly label: string
  decode(input: I): Result<O, DecodeError<I>>
  validate(val: unknown): val is O
}

type DecodedType<D extends Decoder<any, any>> = D extends Decoder<any, infer O>
  ? O
  : never
type InputType<D extends Decoder<any, any>> = D extends Decoder<infer I, any>
  ? I
  : never

const primitiveDecoder = <T>(primitiveType: string): Decoder<any, T> => ({
  label: primitiveType,
  decode(input: any) {
    if (typeof input === primitiveType) {
      return Result.Ok(input as T)
    } else {
      return Result.Error({
        msg: `Error when trying to decode ${input} as ${primitiveType}`,
        input
      })
    }
  },
  validate(val: unknown): val is T {
    return typeof val === primitiveType
  }
})

export const booleanDecoder: Decoder<unknown, boolean> = primitiveDecoder(
  'boolean'
)
export const numberDecoder: Decoder<unknown, number> = primitiveDecoder(
  'number'
)
export const stringDecoder: Decoder<unknown, string> = primitiveDecoder(
  'string'
)
export const nullDecoder: Decoder<unknown, null> = {
  label: 'null',
  decode(input: unknown) {
    return input === null
      ? Result.Ok(input)
      : Result.Error({
          msg: `Error when trying to decode ${input} as null`,
          input
        })
  },
  validate(val: unknown): val is null {
    return val === null
  }
}
export const undefinedDecoder: Decoder<unknown, undefined> = primitiveDecoder(
  'undefined'
)

export const anyValueDecoder: Decoder<unknown, any> = {
  label: 'any',
  decode(input: unknown) {
    return Result.Ok(input)
  },
  validate(val: unknown): val is any {
    return true
  }
}

const tryDecodeObject = <
  OS extends { [k: string]: Decoder<any, any> },
  I extends any,
  O = { [K in keyof OS]: DecodedType<OS[K]> }
>(
  objectShape: OS,
  input: I
): Result<O, DecodeError<any>> => {
  if (typeof input === 'object') {
    const decoders = Object.entries(objectShape).map(([key, decoder]) => ({
      key,
      decoder
    }))
    const decoded = decoders
      .map(({ key, decoder }) => ({
        key,
        decoded: decoder.decode(input[key])
      }))
      .reduce(
        (res, { key, decoded: currentResult }) => {
          return currentResult.match({
            ok(v) {
              return res.map(values => [...values, { key, decoded: v }])
            },
            error(e) {
              return Result.Error({
                msg: `Unable to parse field '${key}':\n${e.msg}`,
                input
              })
            }
          })
        },
        Result.Ok([]) as Result<
          Array<{ key: string; decoded: any }>,
          DecodeError<any>
        >
      )
    return decoded.map(
      pairs =>
        pairs.reduce(
          (obj, pair) => ({
            ...obj,
            [pair.key]: pair.decoded
          }),
          {}
        ) as O
    )
  } else {
    return Result.Error({
      msg: `Error when trying to decode '${input}':\nValue is not an object`,
      input
    })
  }
}

/**
 * Will decode an object of provided shape.
 */
export const objectDecoder = <
  OS extends { [K in keyof O]: Decoder<any, any> },
  I extends any,
  O = { [K in keyof OS]: DecodedType<OS[K]> }
>(
  objectShape: OS
): Decoder<I, O> => {
  return {
    label: 'object',
    decode(input: I) {
      return tryDecodeObject(objectShape, input)
    },
    validate(val: unknown): val is O {
      return tryDecodeObject(objectShape, val).isOk()
    }
  }
}

const tryDecodeArray = <D extends Decoder<any, any>>(
  elementDecoder: D,
  input: any
): Result<DecodedType<D>[], DecodeError<InputType<D>>> => {
  if (Array.isArray(input)) {
    const decoded = input.map(value => elementDecoder.decode(value))
    if (decoded.every(result => result.isOk())) {
      return Result.Ok(decoded.map(result => result.unwrap()))
    } else {
      const firstFailed = decoded.find(result => result.isError())!
      return firstFailed
    }
  } else {
    return Result.Error({
      msg: `Error when trying to decode '${input}':\nValue is not an array`,
      input
    })
  }
}

export const arrayDecoder = <D extends Decoder<any, any>>(
  elementDecoder: D
): Decoder<unknown, DecodedType<D>[]> => ({
  label: 'array',
  decode(input: unknown) {
    return tryDecodeArray(elementDecoder, input)
  },
  validate(val: unknown): val is DecodedType<D>[] {
    return tryDecodeArray(elementDecoder, val).isOk()
  }
})

/**
 * Will match any of the decoders provided within the `decoders` list.
 *
 * If more than one decoder can validate input, first one in the list
 * takes precedence.
 */
export function any<
  D extends Decoder<any, any>,
  I = InputType<D>,
  O = DecodedType<D>
>(decoders: D[]): Decoder<I, O> {
  const decoderLabels = decoders.map(dec => dec.label).join(', ')
  return {
    label: 'any',
    decode(input: I): Result<O, DecodeError<I>> {
      const successfulDecoder = decoders.find(dec => dec.validate(input))
      if (successfulDecoder) {
        return successfulDecoder.decode(input)
      } else {
        return Result.Error({
          msg: `Unable to decode '${input}' as any of [${decoderLabels}]`,
          input
        })
      }
    },
    validate(val: unknown): val is O {
      return !!decoders.find(dec => dec.validate(val))
    }
  }
}

export const lazy = <D extends Decoder<any, any>>(fn: () => D): D => {
  return {
    get label() {
      return fn().label
    },
    decode(input: any) {
      return fn().decode(input)
    },
    validate(val: unknown): val is DecodedType<D> {
      return fn().validate(val)
    }
  } as D
}

/**
 * Makes a decoder optional (will accept `undefined` and `null` values).
 *
 * TODO: wrap output in future `Maybe` type.
 */
export const optional = <D extends Decoder<any, any>>(decoder: D) =>
  any([decoder, nullDecoder, undefinedDecoder])
