import {
  numberDecoder,
  stringDecoder,
  optional,
  objectDecoder,
  Decoder
} from '../Decoder'

export interface Preference {
  id: number
  name: string
  description: string
  nameTranslationKey: string
  descriptionTranslationKey: string
  createdAt: string
  deletedAt?: string
}

export const preferenceDecoder: Decoder<unknown, Preference> = objectDecoder({
  id: numberDecoder,
  name: stringDecoder,
  description: stringDecoder,
  nameTranslationKey: stringDecoder,
  descriptionTranslationKey: stringDecoder,
  createdAt: stringDecoder,
  deletedAt: optional(stringDecoder)
})
