import React, { ReactNode } from 'react'

import styles from './PageHeading.module.scss'
import { joinClasses } from '../utils'

export type Props = {
  prepend?: string
  title: string | ReactNode
  description?: string
  className?: string
}

const PageHeading: React.FC<Props> = ({
  prepend,
  title,
  description,
  className
}) => {
  return (
    <div
      className={joinClasses(styles.pageHeading, className)}
      data-layout="column middle align-center"
    >
      {prepend && <span className={styles.prepend}>{prepend}</span>}
      <h1 className={styles.title}>{title}</h1>
      {description && <p className={styles.description}>{description}</p>}
    </div>
  )
}

export default PageHeading
