import React from 'react'
import { withFormik, FormikProps, FieldArray } from 'formik'
import * as Yup from 'yup'

import styles from './SignUpTopics.module.scss'
import shared from './SignUpShared.module.scss'

import PageHeading from '../../components/PageHeading'
import Issues from '../../components/shared/issues/Issues'

import history from '../../services/History'
import { saveTopics } from '../../api/user/Profile'

type FormProps = {
  topics: number[]
}

const SignUpTopics: React.FC<FormikProps<FormProps>> = ({
  values,
  handleSubmit,
  isValid,
  isSubmitting
}) => {
  return (
    <div className={styles.signUpTopics} data-layout="column align-center">
      <PageHeading
        title="What issues and topics do you care about the most?"
        description="Select 3 or more to continue."
      />
      <form onSubmit={handleSubmit} data-layout="column align-center">
        <button
          style={{ width: '270px', marginBottom: '75px' }}
          disabled={!isValid || isSubmitting}
          type="submit"
        >
          Continue
        </button>
        <FieldArray
          name="topics"
          render={arrayHelpers => (
            <Issues
              handleClick={id => {
                if (!values.topics.includes(id)) arrayHelpers.push(id)
                else {
                  const idx = values.topics.indexOf(id)
                  arrayHelpers.remove(idx)
                }
              }}
              active={id => values.topics.includes(id)}
            />
          )}
        />
      </form>
      <div className={shared.step}>Step 2 of 4</div>
    </div>
  )
}

export default withFormik<{}, FormProps>({
  validationSchema: Yup.object().shape({
    topics: Yup.array()
      .of(Yup.number())
      .min(3)
  }),
  mapPropsToValues: () => ({
    topics: []
  }),
  async handleSubmit({ topics }) {
    try {
      await saveTopics(topics)
      history.push('/sign-up/countries')
    } catch (error) {
      console.error(error)
    }
  }
})(SignUpTopics)
