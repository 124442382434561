import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'

import { BurgerIcon } from '../assets/icons'
import { Logo } from '../assets'
import { toggleSignInModal, setMenuOpen } from '../store/UI/actions'
import { useScrollState, joinClasses } from '../utils'

import styles from './Header.module.scss'
import { ApplicationState } from '../store/reducers'
import { Location } from 'history'
import { getRouteForPathname } from '../constants/routes'
import { getUser } from '../store/User/selectors'
import { requestUserLogout } from '../store/User/actions'
import { User } from '../types/models/User'
import { Option } from '../types/Option'
import ProfileDropdown from './ProfileDropdown'

export type Props = {
  location: Location
  navigateToDashboard: () => void
  openSideMenu: () => void
  requestUserLogout: () => void
  toggleSignInModal: () => void
  user?: User | undefined
}

const Header: React.FC<Props> = ({
  location,
  navigateToDashboard,
  openSideMenu,
  requestUserLogout,
  toggleSignInModal,
  user
}) => {
  const scrolled = useScrollState({ breakpoint: 150 })
  const route = getRouteForPathname(location.pathname)

  const type = route.map(config => config.headerType).unwrapOr('dark')
  const hideRight = route.map(config => config.headerHideRight).unwrapOr(false)
  const sticky = route
    .andThen(config => Option.from(config.headerSticky))
    .unwrapOr(true)

  const donateButtonType = type === 'dark' || scrolled ? 'ghost' : 'white'

  return (
    <header
      className={joinClasses(
        styles.header,
        sticky && styles.scrollable,
        sticky && scrolled && styles.scrolled,
        styles[type]
      )}
      data-layout="row between align-center"
    >
      <div data-layout="row align-center u2">
        <BurgerIcon className={styles.burger} onClick={openSideMenu} />
        <Link to="/">
          <Logo className={styles.logo} />
        </Link>
      </div>
      {!hideRight && (
        <div data-layout="row u2 align-center">
          {user ? (
            <ProfileDropdown
              user={user}
              handleProfileClick={navigateToDashboard}
              handleSignOut={requestUserLogout}
            />
          ) : (
            <Fragment>
              <div className={styles.link} onClick={toggleSignInModal}>
                Sign In
              </div>
              <Link to="/donate">
                <button
                  className={joinClasses(
                    donateButtonType,
                    'medium',
                    'xs-narrow'
                  )}
                >
                  Donate
                </button>
              </Link>
            </Fragment>
          )}
        </div>
      )}
    </header>
  )
}

const mapStateToProps = (state: ApplicationState) => ({
  location: state.router.location,
  user: getUser(state)
})

const mapDispatchToProps = {
  toggleSignInModal,
  requestUserLogout,
  openSideMenu: () => setMenuOpen(true),
  navigateToDashboard: () => push('/dashboard')
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Header)
